export const USER_ROLES = {
  ADMIN: 2,
  TEACHER: 3,
}

export default {
  path: '/admin',
  component: () => import('../views/admin/index/FramePage.vue'),
  redirect: '/admin/main',
  children: [
    // * TEST ROUTE
    // {
    //   path: 'dev',
    //   name: 'Dev',
    //   component: () => import('../views/admin/index/Test.vue'),
    //   meta: {
    //     title: 'dev',
    //     roles: [USER_ROLES.ADMIN, USER_ROLES.TEACHER],
    //   },
    // },
    // * ONLY ADMIN ROUTE
    {
      path: 'statisticalExam',
      name: 'StatisticalExam',
      component: () => import('../views/admin/index/StatisticalExam.vue'),
      meta: {
        title: 'Thống kê đề thi',
        roles: [USER_ROLES.ADMIN],
      },
    },
    {
      path: 'statisticalQuestion',
      name: 'StatisticalQuestion',
      component: () => import('../views/admin/index/StatisticalQuestion.vue'),
      meta: {
        title: 'Thống kê câu hỏi',
        roles: [USER_ROLES.ADMIN],
      },
    },
    {
      path: 'school/class',
      name: 'ClassList',
      component: () => import('../views/admin/schoolClass/ClassList.vue'),
      meta: {
        title: 'Danh sách lớp học',
        keepAlive: true,
        roles: [USER_ROLES.ADMIN],
      },
    },
    {
      path: 'school/schedule',
      name: 'schedulePage',
      component: () => import('../views/admin/schedule/ScheduleManage.vue'),
      meta: {
        title: 'Quản lý thời khóa biểu',
        keepAlive: true,
        roles: [USER_ROLES.ADMIN],
      },
    },
    {
      path: 'school/class/upstaire',
      name: 'UpstairePage',
      component: () => import('../views/admin/schoolClass/UpstairePage.vue'),
      meta: {
        title: 'Lên lớp',
        keepAlive: false,
        roles: [USER_ROLES.ADMIN],
      },
    },
    {
      path: 'school/teacher/assign',
      name: 'AssignTeacher',
      component: () => import('../views/admin/schoolClass/AssignTeachers.vue'),
      meta: {
        title: 'Gán giáo viên theo môn',
        keepAlive: true,
        roles: [USER_ROLES.ADMIN],
      },
    },
    {
      path: 'school/class/info',
      name: 'ClassInfo',
      component: () => import('../views/admin/schoolClass/ClassInfo.vue'),
      meta: {
        title: 'Chi tiết lớp học',
        roles: [USER_ROLES.ADMIN],
      },
    },
    {
      path: 'school/user',
      name: 'UserList',
      component: () => import('../views/admin/schoolUser/UserList.vue'),
      meta: {
        title: 'Quản lý người dùng',
        // keepAlive: true,
        removeKeepAlive: true,
        roles: [USER_ROLES.ADMIN],
      },
    },
    {
      path: 'school/user/checkId',
      name: 'CheckId',
      component: () => import('../views/admin/schoolUser/CheckId.vue'),
      meta: {
        title: 'Tìm kiếm ID học sinh',
        removeKeepAlive: true,
        roles: [USER_ROLES.ADMIN],
      },
    },
    {
      path: 'school/question',
      name: 'SchoolQuestion',
      component: () =>
        import('../views/admin/schoolExam/questionBank/QuestionBank.vue'),
      meta: {
        title: 'Danh sách câu hỏi của trường ',
        keepAlive: true,
        roles: [USER_ROLES.ADMIN],
      },
    },
    {
      path: 'school/question/wordImport',
      name: 'SchoolQuestionWordImport',
      component: () =>
        import(
          '../views/admin/schoolExam/questionBank/QuestionBankWordImport.vue'
        ),
      meta: {
        title: 'Nhập hàng loạt câu hỏi của trường ',
        roles: [USER_ROLES.ADMIN],
      },
    },
    {
      path: 'school/question/add',
      name: 'SchoolQuestionAdd',
      component: () =>
        import(
          '../views/admin/teacherExam/question/TeacherQstAddUpdate.vue'
        ),
      meta: {
        title: 'Tạo câu hỏi',
        roles: [USER_ROLES.ADMIN],

      },
    },
    {
      path: 'school/question/update',
      name: 'SchoolQuestionUpdate',
      component: () =>
        import(
          '../views/admin/teacherExam/question/TeacherQstAddUpdate.vue'
        ),
      meta: {
        title: 'Sửa câu hỏi',
        roles: [USER_ROLES.ADMIN],
      },
    },
    {
      path: 'school/exam',
      name: 'SchoolExam',
      component: () => import('../views/admin/schoolExam/exam/ExamList.vue'),
      meta: {
        title: 'Danh sách đề thi của trường',
        removeKeepAlive: true,
        roles: [USER_ROLES.ADMIN],
      },
    },
    {
      path: 'school/exam/mode',
      name: 'SchoolExamMode',
      component: () => import('../views/admin/schoolExam/exam/ExamAddMode.vue'),
      meta: {
        title: 'Chọn phương thức thêm đề thi',
        roles: [USER_ROLES.ADMIN],
      },
    },
    {
      path: 'school/exam/choiceCard',
      name: 'CreateExamCard',
      component: () => import('../views/admin/schoolExam/exam/NewExamCard.vue'),
      meta: {
        title: 'Chọn bài tập từ kho',
        roles: [USER_ROLES.ADMIN],
      },
    },
    {
      path: 'school/exam/add',
      name: 'SchoolExamAdd',
      component: () => import('../views/admin/task/NewTaskAdd.vue'),
      meta: {
        title: 'Thêm đề thi',
        roles: [USER_ROLES.ADMIN],
      },
    },
    {
      path: 'school/exam/update',
      name: 'SchoolExamUpdate',
      component: () => import('../views/admin/task/NewTaskAdd.vue'),
      meta: {
        title: 'Cập nhật đề thi',
        roles: [USER_ROLES.ADMIN],
      },
    },
    {
      path: 'school/exam/import',
      name: 'SchoolExamImport',
      component: () => import('../views/admin/schoolExam/exam/ExamImport.vue'),
      meta: {
        title: 'Nhập file đề thi',
        roles: [USER_ROLES.ADMIN],
      },
    },
    {
      path: 'school/exam/choice',
      name: 'SchoolExamChoice',
      component: () => import('../views/admin/schoolExam/exam/ExamChoice.vue'),
      meta: {
        title: 'Tạo đề thi từ ngân hàng trường',
        roles: [USER_ROLES.ADMIN],
      },
    },
    {
      path: 'school/practise',
      name: 'SchoolExamPractice',
      component: () =>
        import('../views/admin/schoolExam/practise/PractiseList.vue'),
      meta: {
        title: 'Đề ôn luyện ',
        keepAlive: true,
        roles: [USER_ROLES.ADMIN],
      },
    },
    {
      path: 'message',
      name: 'Message',
      component: () => import('../views/admin/notify/Message.vue'),
      meta: {
        title: 'Thông báo',
        removeKeepAlive: true,
        roles: [USER_ROLES.ADMIN],
      },
    },
    {
      path: 'message/add',
      name: 'MessageAdd',
      component: () => import('../views/admin/notify/MessageAddUpdate.vue'),
      meta: {
        title: 'Thông báo',
        roles: [USER_ROLES.ADMIN],
      },
    },
    {
      path: 'message/update',
      name: 'MessageUpdate',
      component: () => import('../views/admin/notify/MessageAddUpdate.vue'),
      meta: {
        title: 'Thông báo',
        roles: [USER_ROLES.ADMIN],
      },
    },
    {
      path: 'message/info',
      name: 'MessageInfo',
      component: () => import('../views/admin/notify/MessageInfo.vue'),
      meta: {
        title: 'Thông báo',
        roles: [USER_ROLES.ADMIN],
      },
    },
    {
      path: 'transcript',
      name: 'AdminTranscript',
      component: () => import('../views/admin/adminTranscript/index.vue'),
      meta: {
        title: 'Bảng điểm',
        removeKeepAlive: true,
        roles: [USER_ROLES.ADMIN],
      },
    },
    {
      path: 'transcript/classTranscript',
      name: 'ClassTranscript',
      component: () =>
        import('../views/admin/adminTranscript/ClassTranscript.vue'),
      meta: {
        title: 'Bảng điểm của lớp học',
        removeKeepAlive: true,
        roles: [USER_ROLES.ADMIN],
      },
    },
    {
      path: 'transcript/studentTranscript',
      name: 'StudentTranscript',
      component: () =>
        import('../views/admin/adminTranscript/StudentTranscript.vue'),
      meta: {
        title: 'Bảng điểm của học sinh',
        removeKeepAlive: true,
        roles: [USER_ROLES.ADMIN],
      },
    },
    // * ONLY TEACHER ROUTE
    {
      path: 'personalInfo',
      name: 'PersonalInfo',
      component: () => import('../views/admin/index/PersonalInfo.vue'),
      meta: {
        title: 'Thông tin cá nhân',
        roles: [USER_ROLES.TEACHER],
      },
    },
    {
      path: 'teacher/task',
      name: 'TeacherTaskList',
      component: () => import('../views/admin/task/TaskList.vue'),
      meta: {
        title: 'Bài tập',
        keepAlive: true,
        roles: [USER_ROLES.TEACHER],
      },
    },
    {
      path: 'teacher/task/mode',
      name: 'TeacherTaskMod',
      component: () => import('../views/admin/task/TaskAddMode.vue'),
      meta: {
        title: 'Chọn phương thức thêm bài tập',
        roles: [USER_ROLES.TEACHER],
      },
    },
    {
      path: 'task/choiceCard',
      name: 'createChoiceCard',
      component: () => import('../views/admin/task/NewChoiceCard.vue'),
      meta: {
        title: 'Chọn bài tập từ kho',
        roles: [USER_ROLES.TEACHER],
      },
    },
    {
      path: 'teacher/teacherPaper/choice',
      name: 'TeacherPaperChoice',
      component: () => import('../views/admin/task/TaskChoice.vue'),
      meta: {
        title: 'Chọn đề thi từ kho',
        roles: [USER_ROLES.TEACHER],
      },
    },
    {
      path: 'teacher/task/add',
      name: 'TeacherTaskAdd',
      component: () => import('../views/admin/task/NewTaskAdd.vue'),
      meta: {
        title: 'Thêm bài tập',
        roles: [USER_ROLES.TEACHER],
      },
    },
    // {
    //   path: 'teacher/task/structure',
    //   name: 'TeacherTaskStructure',
    //   component: () => import("../views/admin/task/TaskStructure.vue"),
    //   meta: {
    //     title: 'Tạo đề thi từ ma trận đề thi ',
    //     roles: [USER_ROLES.TEACHER]
    //   }
    // },
    {
      path: 'teacher/task/choice',
      name: 'TeacherTaskChoice',
      component: () => import('../views/admin/task/TaskChoice.vue'),
      meta: {
        title: 'Tạo đề thi từ ma trận đề thi ',
        roles: [USER_ROLES.TEACHER],
      },
    },
    {
      path: 'teacher/task/import',
      name: 'TeacherTaskImport',
      component: () => import('../views/admin/task/TaskImport.vue'),
      meta: {
        title: 'Nhập file bài tập',
        roles: [USER_ROLES.TEACHER],
      },
    },
    {
      path: 'teacher/task/update',
      name: 'TeacherTaskUpdate',
      component: () => import('../views/admin/task/NewTaskAdd.vue'),
      meta: {
        title: 'Cập nhật bài tập',
        roles: [USER_ROLES.TEACHER],
      },
    },
    {
      path: 'teacher/paperList',
      name: 'PublicPaperList',
      component: () =>
        import('../views/admin/teacherExam/exam/PublicPaperList.vue'),
      meta: {
        title: 'Danh sách đề thi',
        removeKeepAlive: true,
        roles: [USER_ROLES.TEACHER],
      },
    },
    {
      path: 'teacher/teacherPaper',
      name: 'PersonalPaperBank',
      component: () =>
        import('../views/admin/teacherExam/exam/personalPaperBank.vue'),
      meta: {
        title: 'Danh sách đề thi của giáo viên',
        removeKeepAlive: true,
        roles: [USER_ROLES.TEACHER],
      },
    },
    {
      path: 'teacher/teacherPaper/mode',
      name: 'TeacherPaperMode',
      component: () => import('../views/admin/task/TaskAddMode.vue'),
      meta: {
        title: 'Chọn phương thức thêm đề thi',
        roles: [USER_ROLES.TEACHER],
      },
    },
    {
      path: 'teacher/teacherPaper/add',
      name: 'TeacherPaperAdd',
      component: () => import('../views/admin/task/NewTaskAdd.vue'),
      meta: {
        title: 'Thêm đề thi',
        roles: [USER_ROLES.TEACHER],
      },
    },
    {
      path: 'teacher/teacherQuestion/add',
      name: 'TeacherQuestionAdd',
      component: () =>
        import('../views/admin/teacherExam/question/TeacherQstAddUpdate.vue'),
      meta: {
        title: 'Thêm câu hỏi',
        roles: [USER_ROLES.TEACHER],
      },
    },
    {
      path: 'teacher/teacherQuestion/update',
      name: 'TeacherQuestionUpdate',
      component: () =>
        import('../views/admin/teacherExam/question/TeacherQstAddUpdate.vue'),
      meta: {
        title: 'Sửa câu hỏi ',
        removeKeepAlive: true,
        roles: [USER_ROLES.TEACHER],
      },
    },
    {
      path: 'teacher/teacherQuestion/import',
      name: 'TeacherQuestionImport',
      component: () =>
        import('../views/admin/teacherExam/question/TeacherQstWordImport.vue'),
      meta: {
        title: 'Nhập câu hỏi',
        removeKeepAlive: true,
        roles: [USER_ROLES.TEACHER],
      },
    },
    {
      path: 'classHome/task',
      name: 'ClassHomeTask',
      component: () =>
        import('../views/admin/teacherClass/task/ClassTaskList.vue'),
      meta: {
        title: 'Danh sách bài tập lớp',
        removeKeepAlive: true,
        roles: [USER_ROLES.TEACHER],
      },
    },
    {
      path: 'classHome/task/mode',
      name: 'ClassHomeTaskMode',
      component: () => import('../views/admin/task/TaskAddMode.vue'),
      meta: {
        title: 'Chọn phương thức thêm bài tập lớp',
        roles: [USER_ROLES.TEACHER],
      },
    },
    {
      path: 'classHome/task/add',
      name: 'classHomeTaskAdd',
      component: () => import('../views/admin/task/NewTaskAdd.vue'),
      meta: {
        title: 'Thêm bài tập lớp',
        roles: [USER_ROLES.TEACHER],
      },
    },

    {
      path: 'classHome/task/choice',
      name: 'ClassHomeTaskChoice',
      component: () => import('../views/admin/task/TaskChoice.vue'),
      meta: {
        title: 'Chọn từ kho để tạo bài tập',
        roles: [USER_ROLES.TEACHER],
      },
    },
    {
      path: 'classHome/task/import',
      name: 'ClassHomeTaskImport',
      component: () => import('../views/admin/task/TaskImport.vue'),
      meta: {
        title: 'Nhập bài tập lớp',
        roles: [USER_ROLES.TEACHER],
      },
    },
    {
      path: 'classHome/task/info',
      name: 'ClassHomeTaskInfo',
      component: () =>
        import('../views/admin/teacherClass/task/ClassTaskInfo.vue'),
      meta: {
        title: 'Chi tiết bài tập lớp',
        removeKeepAlive: true,
        roles: [USER_ROLES.TEACHER],
      },
    },
    {
      path: 'classHome/task/mixExam',
      name: 'MixExamDetail',
      component: () => import('../views/admin/teacherClass/task/MixExam.vue'),
      meta: {
        title: 'Danh Sách đề đảo',
        removeKeepAlive: true,
        roles: [USER_ROLES.TEACHER],
      },
    },
    {
      path: 'classHome/task/mark',
      name: 'ClassHomeTaskMark',
      component: () => import('../views/admin/teacherClass/task/MarkPaper.vue'),
      meta: {
        title: 'Chấm điểm bài tập lớp',
        removeKeepAlive: true,
        roles: [USER_ROLES.TEACHER],
      },
    },
    {
      path: 'classHome/task/markBridge',
      name: 'MarkBridge',
      component: () =>
        import('../views/admin/teacherClass/task/MarkBridge.vue'),
      meta: {
        title: 'MarkBridge',
        removeKeepAlive: true,
        roles: [USER_ROLES.TEACHER],
      },
    },
    {
      path: 'teacher/statistical',
      name: 'teacherStatistic',
      component: () =>
        import('../views/admin/teacherStatistic/TeacherStatistic.vue'),
      meta: {
        title: 'Thống kê',
        roles: [USER_ROLES.TEACHER],
      },
    },

    {
      path: 'teacher/statistical/userScore',
      name: 'avgUserScore',
      component: () =>
        import('../views/admin/teacherStatistic/AvgUserScore.vue'),
      meta: {
        title: 'Thống kê điểm',
        roles: [USER_ROLES.TEACHER],
      },
    },
    {
      path: 'teacher/statistical/classList',
      name: 'classListStatistic',
      component: () => import('../views/admin/teacherStatistic/ClassList.vue'),
      meta: {
        title: 'Thống kê lớp học',
        roles: [USER_ROLES.TEACHER],
      },
    },
    {
      path: 'teacher/statistical/paperList',
      name: 'paperListStatistic',
      component: () => import('../views/admin/teacherStatistic/PaperList.vue'),
      meta: {
        title: 'Thống kê bài tập',
        roles: [USER_ROLES.TEACHER],
      },
    },
    {
      path: 'teacher/statistical/paperClass',
      name: 'studentsPaperList',
      component: () =>
        import('../views/admin/teacherStatistic/StudentsPaperList.vue'),
      meta: {
        title: 'Thống kê bài tập lớp',
        removeKeepAlive: true,
        roles: [USER_ROLES.TEACHER],
      },
    },
    {
      path: 'classHome',
      name: 'classHome',
      component: () => import('../views/admin/teacherClass/ClassHome.vue'),
      meta: {
        title: 'Trang chủ',
        removeKeepAlive: true,
        roles: [USER_ROLES.TEACHER],
      },
    },
    {
      path: 'classHome/examList',
      name: 'newExamList',
      component: () => import('../views/admin/teacherClass/NewExamList.vue'),
      meta: {
        title: 'Danh sách đề thi mới',
        removeKeepAlive: true,
        roles: [USER_ROLES.TEACHER],
      },
    },
    {
      path: 'classHome/questionList',
      name: 'newqQestionList',
      component: () =>
        import('../views/admin/teacherClass/NewQuestionList.vue'),
      meta: {
        title: 'Danh sách câu hỏi mới',
        removeKeepAlive: true,
        roles: [USER_ROLES.TEACHER],
      },
    },
    {
      path: 'classHome/info',
      name: 'classHomeInfo',
      component: () => import('../views/admin/teacherClass/ClassInfo.vue'),
      meta: {
        title: 'Chi tiết lớp học',
        roles: [USER_ROLES.TEACHER],
      },
    },
    {
      path: 'classHome/student',
      name: 'classHomeStudent',
      component: () => import('../views/admin/teacherClass/StudentList.vue'),
      meta: {
        title: 'Danh sách học sinh',
        keepAlive: true,
        roles: [USER_ROLES.TEACHER],
      },
    },
    {
      path: 'classHome/student/task',
      name: 'classHomeStudentTask',
      component: () => import('../views/admin/teacherClass/StudentTask.vue'),
      meta: {
        title: 'Danh sách bài tập',
        roles: [USER_ROLES.TEACHER],
      },
    },
    {
      path: 'classHome/teacher',
      name: 'classHomeTeacher',
      component: () => import('../views/admin/teacherClass/TeacherList.vue'),
      meta: {
        title: 'Danh sách giáo viên',
        roles: [USER_ROLES.TEACHER],
      },
    },
    {
      path: 'teacher/teacherPaper/import',
      name: 'TeacherPaperImport',
      component: () => import('../views/admin/task/TaskImport.vue'),
      meta: {
        title: 'Nhập đề thi',
        roles: [USER_ROLES.TEACHER],
      },
    },
    {
      path: 'teacher/teacherPaper/update',
      name: 'TeacherPaperUpdate',
      component: () => import('../views/admin/task/NewTaskAdd.vue'),
      meta: {
        title: 'Cập nhật đề thi',
        roles: [USER_ROLES.TEACHER],
      },
    },
    {
      path: 'teacher/message',
      name: 'TeacherMessage',
      component: () => import('../views/admin/notify/TeacherMessage.vue'),
      meta: {
        title: 'Thông báo',
        removeKeepAlive: true,
        roles: [USER_ROLES.TEACHER],
      },
    },
    {
      path: 'statistical',
      name: 'Statistical',
      component: () => import('../views/admin/statistical/Statistical.vue'),
      meta: {
        title: 'Thông báo',
        removeKeepAlive: true,
        roles: [USER_ROLES.TEACHER],
      },
    },
    {
      path: 'teacher/transcript',
      name: 'TeacherTranscripts',
      component: () =>
        import('../views/admin/teacherTranscript/TeacherTranscript.vue'),
      meta: {
        title: 'Bảng điểm',
        removeKeepAlive: true,
        roles: [USER_ROLES.TEACHER],
      },
    },
    {
      path: 'teacher/schedule',
      name: 'TeacherSchedule',
      component: () =>
        import('../views/admin/teacherSchedule/TeacherSchedule.vue'),
      meta: {
        title: 'Thời khóa biểu',
        removeKeepAlive: true,
        roles: [USER_ROLES.TEACHER],
      },
    },
    {
      path: 'teacher/questionBank',
      name: 'TeacherQuestionBank',
      component: () =>
        import('../views/admin/teacherExam/question/TeacherQuestionBank.vue'),
      meta: {
        title: 'Ngân hàng câu hỏi',
        removeKeepAlive: true,
        roles: [USER_ROLES.TEACHER],
      },
    },
    {
      path: 'teacher/personalQuestionBank',
      name: 'personalQuestionBank',
      component: () =>
        import('../views/admin/teacherExam/question/PersonalQuestionBank.vue'),
      meta: {
        title: 'Kho cá nhân',
        removeKeepAlive: true,
        roles: [USER_ROLES.TEACHER],
      },
    },
    // * PUBLIC ROUTE
    {
      path: 'termsService',
      name: 'TermsService',
      component: () => import('../views/admin/index/TermsService.vue'),
      meta: {
        title: 'Điều khoản dịch vụ',
        roles: [USER_ROLES.ADMIN, USER_ROLES.TEACHER],
      },
    },
    {
      path: 'main',
      name: 'AdminMain',
      component: () => import('../views/admin/index/Home.vue'),
      meta: {
        title: 'Trang chủ',
        roles: [USER_ROLES.ADMIN, USER_ROLES.TEACHER],
      },
    },
    // * GARBAGE ROUTES
    // {
    //   path: 'mainsub',
    //   name: 'main',
    //   component: () => import("../views/admin/index/Main.vue"),
    //   meta: {
    //     title: 'Trang chủ'
    //   }
    // },
    //权限管理 Rights management
    // {
    //     path: 'power',
    //     name: '权限管理',
    //     component: () => import("../views/admin/system/power/PowerManage.vue"),
    //     meta: {
    //         title: 'Quản lý quyền hạn',
    //         removeKeepAlive: true
    //     }
    // },
    // {
    //   path: 'teacher/schoolPaper',
    //   name: '老师学校试卷列表',
    //   component: () => import("../views/admin/teacherExam/exam/SchoolPaperList.vue"),
    //   meta: {
    //     title: 'Danh sách đề thi của trường',
    //     removeKeepAlive: true
    //   }
    // },
    // {
    //   path: 'teacher/ftechPaper',
    //   name: '老师Ftech试卷列表',
    //   component: () => import("../views/admin/teacherExam/exam/FtechPaperList.vue"),
    //   meta: {
    //     title: 'Danh sách đề thi của Fschool',
    //     removeKeepAlive: true
    //   }
    // },
    // {
    //   path: 'teacher/teacherPaper/structure',
    //   name: '从结构创建老师试卷',
    //   component: () => import("../views/admin/task/TaskStructure.vue"),
    //   meta: {
    //     title: 'Tạo đề thi từ ma trận đề thi',
    //   }
    // },
    // {
    //   path: 'teacher/teacherQuestionBank',
    //   name: 'TeacherQuestionBank',
    //   component: () => import("../views/admin/teacherExam/question/teacherQuestionBank.vue"),
    //   meta: {
    //     title: 'Danh sách câu hỏi của trường ',
    //     keepAlive: true,
    //     // removeKeepAlive: true,
    //     roles: [USER_ROLES.TEACHER]
    //   }
    // },
    // {
    //   path: 'teacher/schoolQuestionList',
    //   name: '',
    //   component: () => import("../views/admin/teacherExam/question/SchoolQuestionList.vue"),
    //   meta: {
    //     title: 'Danh sách câu hỏi của trường ',
    //     // removeKeepAlive: true
    //   }
    // },
    // {
    //   path: 'teacher/structure',
    //   name: '老师端试卷结构列表',
    //   component: () => import("../views/admin/teacherExam/structure/StructureList.vue"),
    //   meta: {
    //     title: 'Danh sách ma trận đề thi',
    //     keepAlive: true,
    //   }
    // },
    // {
    //   path: 'teacher/structure/add',
    //   name: 'addTestStructure',
    //   component: () => import("../views/admin/teacherExam/structure/StructureAddUpdate.vue"),
    //   meta: {
    //     title: 'Thêm ma trận đề thi ',
    //   }
    // },
    // {
    //   path: 'teacher/structure/update',
    //   name: 'updateTestStructure',
    //   component: () => import("../views/admin/teacherExam/structure/StructureAddUpdate.vue"),
    //   meta: {
    //     title: 'Sửa  ma trận đề thi ',
    //   }
    // },
    // * UNDETEC
    // {
    //   path: 'school',
    //   name: 'SchoolList',
    //   component: () => import("../views/admin/organize/school/SchoolList.vue"),
    //   meta: {
    //     title: 'Quản lý trường học',
    //     keepAlive: true
    //   }
    // },
    // {
    //   path: 'school/info',
    //   name: 'SchoolInfo',
    //   component: () => import("../views/admin/organize/school/SchoolInfo.vue"),
    //   meta: {
    //     title: 'Chi tiết trường học'
    //   }
    // },
    // {
    //   path: 'school/info/classInfo',
    //   name: 'ClassInfo',
    //   component: () => import("../views/admin/organize/class/ClassInfo.vue"),
    //   meta: {
    //     title: 'Chi tiết lớp học'
    //   }
    // },
    // {
    //     path: 'school/structure',
    //     name: '学校试卷结构',
    //     component: () => import("../views/admin/schoolExam/structure/StructureList.vue"),
    //     meta: {
    //         title: 'Ma trận đề thi của trường ',
    //         keepAlive: true
    //     }
    // },
    // {
    //     path: 'school/structure/add',
    //     name: '添加试卷结构',
    //     component: () => import("../views/admin/schoolExam/structure/StructureAddUpdate.vue"),
    //     meta: {
    //         title: 'Thêm ma trận đề thi ',
    //     }
    // },    {
    //     path: 'school/structure/update',
    //     name: '修改试卷结构',
    //     component: () => import("../views/admin/schoolExam/structure/StructureAddUpdate.vue"),
    //     meta: {
    //         title: 'Sửa  ma trận đề thi ',
    //     }
    // },
    // {
    //   path: 'classHome/task/structure',
    //   name: '从结构创建班级作业',
    //   component: () => import("../views/admin/task/TaskStructure.vue"),
    //   meta: {
    //     title: 'Tạo bài tập lớp từ ma trận',
    //   }
    // },
    // {
    //   path: 'teacher/ftechQuestionList',
    //   name: 'Ftech试题列表',
    //   component: () => import("../views/admin/teacherExam/question/FtechQuestionList.vue"),
    //   meta: {
    //     title: 'Danh sách câu hỏi của Fschool',
    //     // removeKeepAlive: true
    //   }
    // },
    // {
    //   path: 'teacher/teacherQuestion',
    //   name: 'Teacher试题列表',
    //   component: () => import("../views/admin/teacherExam/question/TeacherQuestionList.vue"),
    //   meta: {
    //     title: 'Danh sách câu hỏi',
    //     // keepAlive: true,
    //   }
    // },

    // 老师端 试卷结构部分 The teacher ends the structure of the test paper

    // 老师端 班级部分 The teacher holds the class part
    // {
    //   path: 'classHome/teacher/task',
    //   name: 'classHomeTeacherTask',
    //   component: () => import("../views/admin/teacherClass/TeacherTask.vue"),
    //   meta: {
    //     title: 'Danh sách bài tập',
    //   }
    // },
    //讲师消息
  ],
}
