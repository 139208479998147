const getDifficultyList = async () => {
  //获取难度列表 Get the difficulty list
  let res = await $.Axios({
    url: '/admin/paper/findPaperDifficultyTypeList?' + encrypt.encryptUrlData(),
  })

  if (res.ok) {
    return res.entityList
  }
}

const getDifficultyListNotUndetermined = async () => {
  //获取难度列表 Get the difficulty list
  let res = await $.Axios({
    url:
      '/admin/question/findDifficultyListNotUndetermined?' +
      encrypt.encryptUrlData(),
  })

  if (res.ok) {
    return res.entityList
  }
}

export default {
  getDifficultyList,
  getDifficultyListNotUndetermined,
}
