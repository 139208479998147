import branchService from "../../services/branch.service"
import classService from "../../services/class.service"
import difficultyService from "../../services/difficulty.service"
import questionService from "../../services/question.service"

const getDefaultState = () => {
  return {
    fullBranchList: null,
    fullGradeList: null,
    questionTypeList: null,
    difficultyList: null,
  }
}

const setState = (commit, key, value) => {
  commit('setValue', { key, value })
}

export default {
  state: {
    ...getDefaultState()
  },
  mutations: {
    setValue(state, target) {
      state[target.key] = target.value
    },
    resetState(state) {
      Object.assign(state, getDefaultState())
    }
  },
  actions: {
    async findFullBranchList({ state, commit }) {
      try {
        if (state.fullBranchList === 'pending') return
        setState(commit, 'fullBranchList', 'pending')
        const branchList = await branchService.findBranchList()
        setState(commit, 'fullBranchList', branchList)
      } catch (error) {
        setState(commit, 'fullBranchList', null)
        console.error(error)
      }
    },
    async findFullGradeList({ commit, rootState, dispatch, state }) {
      try {
        if (state.fullGradeList === 'pending') return
        setState(commit, 'fullGradeList', 'pending')

        if (!rootState.schoolInfo) await dispatch('findSchoolById')
        const gradeList = await classService.findClassListByLevel(rootState.schoolInfo?.level)
        setState(commit, 'fullGradeList', gradeList)
      } catch (error) {
        setState(commit, 'fullGradeList', null)
        console.error(error)
      }
    },
    async findQuestionTypeList({ state, commit }) {
      try {
        if (state.questionTypeList === 'pending') return
        setState(commit, 'questionTypeList', 'pending')

        const questionTypeList = await questionService.getQuestionTypeList()
        setState(commit, 'questionTypeList', questionTypeList)
      } catch (error) {
        setState(commit, 'questionTypeList', null)
        console.error(error)
      }
    },
    async findDifficultyList({ state, commit }) {
      try {
        if (state.difficultyList === 'pending') return
        setState(commit, 'difficultyList', 'pending')

        const difficultyList = await difficultyService.getDifficultyListNotUndetermined()
        setState(commit, 'difficultyList', difficultyList)
      } catch (error) {
        setState(commit, 'difficultyList', nulll)
        console.error(error)
      }
    }
  }
}