export default {
  headerTxt: {
    maximize: '全屏',
    reduction: '取消全屏',
    personal: '个人中心',
    logout: '退出',
    updatePwd: '修改密码',
    pointMe: '点我',
  },
  loginTxt: {
    title: '登录',
    loginBtn: '登陆',
    verTxt: {
      userName: '请输入用户名',
      userLengthLimt: '用户名长度为6到100位',
      password: '请输入密码',
      lengthLimt: '密码长度为6到32位',
    },
    rememberPassword: '记住密码',
    sliderText: '拖动以完成图片',
    clickRefresh: '点击刷新图片',
  },
  placeholder: {
    userName: '请输入用户名',
    password: '请输入密码',
  },
  tips: {
    login: '登陆成功！',
    sucTitle: '成功',
    errTitle: '错误',
    warTitle: '警告',
    infoTitle: '消息',
  },
  mainTxt: {
    dashboard: '仪表盘',
  },

  publicTxt: {
    empty: '暂无数据！',
    state: '状态',
    provinceCity: '省/市',
    areaCounty: '区/县',
    addrs: '地址',
    contactInfo: '联系信息',
    operate: '操作',
    type: '类型',
    detailsTxt: '详情',
    cancelBtnTxt: '取消',
    confirmBtnTxt: '确认',
    conserveBtnTxt: '保存',
    downloadExcelBtn: 'Excel 下载',
    downloadExcelTemplateBtn: ' Excel 模板下载',
    uploadExcelBtn: ' Excel 上传',
    activeTxt: '激活',
    notActiveTxt: '未激活',
    confirmDelTxt: '确定删除',
    confirmDelTxt2: '你确定要将学校状态改为未活动并删除学校',
    agreeTxt: '同意',
    resetTxt: '重置',
    addTxt: '添加',
    fileAnalysis: '文件上传解析中...',
    homePage: '概览',
    createTime: '创建时间',
    verTxt: {
      numberOrLetter: '请输入数字或字母',
    },
    importExcel: '导入Excel',
    selectFile: '选择文件',
    notSelectFile: '未选择任何文件',
    upload: '上传',
    record: '记录',
    uploadError: '上传失败',
    lookErrorRecord: '查看错误记录',
  },

  schoolTxt: {
    title: '组织管理',
    addBtn: '添加',
    currentSchoolList: '当前学校列表',
    delSchoolList: '已删除学校列表 ',
    schoolName: '学校名称',
    // schoolId: '学校ID',
    schoolLevel: '学校等级',
    nature: '性质',
    schoolSort: '学校分类',
    // schoolNameAndId: '输入学校名称与学校ID',
    inputSchoolName: '输入学校名称',
    addSchoolTitle: '添加学校',
    updateSchoolTitle: '更新学校',
    repName: '代表人名称',
    repMobile: '代表人电话',
    repEMail: '代表人的邮箱',
    contactName: '联系人名称',
    contactMobile: '联系人电话',
    schoolBranchInfo: '学校与学校分支的信息',
    classList: '班级列表',
    schoolAccountList: '学校账号列表',
    backList: '返回学校列表',
    // synchroSchool: '同步学校信息',
    schoolInfo: '学校信息',
    branchList: '学校分支列表',
    schoolBranchName: '学校分支名称',
    schoolBranchId: '学校分支ID',
    schoolBranchAddr: '学校分支地址',
    addBranchTitle: '添加学校分支',
    updateBranchTitle: '更新学校分支',

    delSchool: '删除学校',
    recoverySchool: '恢复学校',
    recoverySchoolTxt: '你确定要恢复学校',

    delBranchSchool: '删除学校分支',
    delBranchSchoolTxt: '你确定要删除学校分支',
    verTxt: {
      provincesCityId: '请选择省/市',
      regionId: '请选择区/县',
      level: '请选择学校等',
      nature: '请选择性质',
      type: '请选择分类',
      schoolName: '请选择学校',
      address: '请输入地址',
      deputyUserName: '请输入代表人名',
      deputyUserTel: '请输入代表人电话',
    },
  },
  classTxt: {
    inputClassNmae: '输入班级名称',
    grade: '年级',
    // synchroClass: '同步班级信息',
    className: '班级名称',
    classId: '班级ID',
    addClassTitle: '添加班级',
    updateClassTitle: '更新班级',
    teacherListTxt: '老师列表',
    studentListTxt: '学生列表',
    verTxt: {
      className: '请输入班级名称',
      className_l_l: '班级名称长度为1到10位',
      classId: '请输入班级ID',
      classId_l_l: '班级ID长度为1到10位',
      grade: '请选择年级',
      teacherId: '请选择老师',
      subjectId: '请选择科目',
      moveToClass: '请选要移动到的班级',
    },
    delClass: '删除班级',
    delClassTxt: '你确定要删除班级',
    distributeTeacher: '分配老师',
    cutClass: '切换班级',
    subject: '科目',
    studentName: '学生名称',

    updateDistributeTxt: '更改教师信息',

    distribute: '分配',
    otherSbject: '其他科目',
    removeTeacher: '移除老师',
    removeTeacherTxt: '确定要从班级中删除教师',
    selectAll: '全选',
    changeClass: '更换班级',
    moveToClass: '移到的班级',
    moveTo: '移到',
  },
  accountTxt: {
    controller: '管理者',
    teacherTxt: '老师',
    studentTxt: '学生',
    addController: '添加管理者',
    activeTxt: '活跃',
    notActiveTxt: '不活跃',
    addControllerTxt: '添加管理者账户',
    updateControllerTxt: '修改管理者账户',
    updateControllerState: '更换管理者状态',
    updateControllerStateTxt: '你确认想更换状态吗？',
    // updateControllerStateTxt: '你确认想更换状态成不活动吗？',
    delController: '删除管理者',
    delControllerTxt: '你确认想删除管理者吗?',
    updatePasswor: '修改密码',
    verTxt: {
      userName: '请输入用户名',
      userName_l_l: '用户名长度为6到32位',
      password: '请输入密码',
      password_l_l: '密码长度为6到32位',
      confirmPwd: '请再次输入密码',
      atypism: '两次密码不一致',
      name: '请输入姓名',
      userKey: '请输入学员ID',
      classId: '请选择班级',
      school: '请选择学校',
    },
    teacherId: '老师ID',
    inputTeacherId: '输入老师ID',

    addTeacherTxt: '添加老师账户',
    updateTeacherTxt: '修改老师账户',

    updateTeacherState: '更换老师状态',
    updateTeacherStateTxt: '你确认想更换状态吗？',
    // updateTeacherStateTxt: '你确认想更换状态成活动吗？',
    delTeacher: '删除老师',
    delTeacherTxt: '你确认想删除这位老师吗？ ',

    studentId: '学生ID',
    inputStudentId: '输入学生ID',
    birthday: '出生日期',
    gender: '性别',
    men: '男',
    women: '女',

    addStudentTxt: '添加学生账号',
    updateStudentTxt: '修改学生账号',

    updateStudentState: '更换学生状态',
    updateStudentStateTxt: '你确认想更换状态吗？',
    delStudentTxt: '你确认想删除这位学生吗？',
    delStudent: '删除学生',
    teacherName: '老师名称',
  },

  userTxt: {
    userTxt: '用户',
    title: '用户管理',
    accountManage: '账号管理',
    roleManage: '角色管理',
    addBtn: '创建',
    inputName: '输入姓名',
    inputUserNmae: '输入用户名',
    role: '角色',
    name: '姓名',
    userName: '用户名',
    roleName: '角色名称',
    accountNum: '分配了角色的帐号',
    addMaskTitle: '添加 Admin账号',
    updateMaskTitle: '修改 Admin账号',
    passwordTxt: '密码',
    confirmPwdTxt: '确认密码',
    canVisitSchool: '可访问的学校',
    addRoleTitle: '添加角色名称',
    updateRoleTitle: '修改角色名称',
    email: '邮箱',
    mobile: '手机号',
    allTxt: '全部',
    partTxt: '部分',

    delUser: '删除Admin账号',
    delUserTxt: '您确定要删除此管理员帐户吗？',

    delRole: '删除角色',
    delRoleTxt: '你确定要删除此角色吗？',

    updateUserState: '更换 Admin状态',
    updateUserStateTxt: '你确认想更换状态吗？',
    verTxt: {
      roleName: '请输入角色名称',
      roleName_l_l: '角色名称长度为1到32位',
    },

    find: '找到',
    aResult: '个结果',
    pwdTxt: '密码',
  },

  questionTxt: {
    questionBank: '题库',
    topicContent: '题目内容',
    difficulty: '难度',
    special: '专题',
    theme: '主题',
    knowledgePoints: '知识点',
    analysis: '解析',
    venogram: '脉络',
    content: '内容',
    infof: '信息',

    commonlyInfo: '一般信息',
    detailedInfo: '详细信息',
    questionType: '题型',
    syncTime: '同步时间',
  },

  examTxt: {
    testPaper: '试卷题库',
    testPaperBank: '试卷题库',
    inputTestPaperName: '请输入试卷名称',
    topicQuantity: '题量',
    time_min: '时间（分钟）',
    topicType: '题类',
    release: '发布',
    place: '地点',
    conversionTestPaper: '转级试卷',
    courseTestPaper: '按照课程的试卷',
    contestTestPaper: '竞赛试卷，奥林匹克试卷等',
    showAnswer: '展示答案',
    order: '次序',
    answer: '答案',
    uploadAnswer: '笔试题-上传答案',
    rightAnswer: '正确答案',
    explain: '解释',
    hotPaper: '热门试卷',
    newPaper: '新试卷',
  },
}
