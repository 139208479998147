import params from '@/common/common.js'
import langConfig from '@/common/lang/langConfig'
const currentLang = langConfig.langPacket.global.locale
const i18n = langConfig.langPacket.global.messages.value[currentLang.value]

const getProps = (obj, keyString) => {
  const keyStringArr = keyString.split('.')
  if (!obj || !keyString) return ''
  return keyStringArr.reduce((prev, current) => {
    let res = !prev ? obj[current] : prev[current]
    return res
  }, '')
}

const checkTitle = (question) => {
  return !(question.title || question.statementCurrent)
}

const checkAnswer = (question) => {
  if (!question) return true
  let state = false
  switch (true) {
    case ['single', 'multiple'].includes(question.type): {
      const temp = question.questionContent.optList || []
      const rightAnswer = temp.find((item) => item.answer === 1)
      if (!rightAnswer) state = true
      break
    }
    case question.type === 'blanks': {
      const temp = question.questionContent.blanksOptList || []
      const emptyAnswer = temp.find((item) => !item.answer)
      if (emptyAnswer) state = true
      break
    }
    case question.type === 'short': {
      // state = !question?.questionContent?.answerText
      // state = !question?.analysis
      // break
      return false
    }
    case question.type === 'synthesize': {
      return false
    }
  }
  return state
}

const checkOptContent = (question) => {
  if (question.type != 'short' && (!question || !question.questionContent))
    return true
  let state = false
  switch (true) {
    case ['single', 'multiple'].includes(question.type): {
      state = !(
        question.questionContent && question.questionContent.optList.length
      )
      if (!state) {
        question.questionContent.optList.forEach((item) => {
          if (!item.optContext) {
            state = true
            return state
          }
        })
      }
      break
    }
    case question.type === 'blanks': {
      state = !(
        question.questionContent &&
        question.questionContent.blanksOptList &&
        question.questionContent.blanksOptList.length
      )
      break
    }
    case question.type === 'short': {
      state = false
      break
    }
    case question.type === 'synthesize': {
      return false
    }
  }
  return state
}

const checkAnalysis = (question, originImport = false) => {
  if (originImport) {
    if (
      question &&
      !question.analysis &&
      question.type == 'short' &&
      question._isCheck
    ) {
      return true
    }
    return false
  } else {
    if (!question) return true
    if (question.type === 'synthesize') return false
    if (!question.analysis) return true
    return false
  }
}

/**
 * Check knowl edge is choosen.
 * @param {object} question - data question.
 */
const checkKnowlEdge = (question) => {
  if (
    (question._isCheck && !question.knowledgeTreeIds) ||
    (question._isCheck && question.knowledgeTreeIds == '0')
  ) {
    return true
  } else {
    return false
  }
}

const showFailMessage = (listFailOrder, type) => {
  const data =
    listFailOrder.length > 1 ? listFailOrder.join(', ') : listFailOrder[0]
  switch (type) {
    case 'titleFail': {
      params.promptMsg(
        `${getProps(
          i18n,
          'updateQstTips.front_topic_txt',
        )} [${data}]: ${getProps(
          i18n,
          'updateQstTips.pleaseSetSectionTxt',
        )} ${getProps(i18n, 'updateQstTips.topic_problemTxt')}`,
        'war',
      )
      break
    }
    case 'contentFail': {
      params.promptMsg(
        `${getProps(
          i18n,
          'updateQstTips.front_topic_txt',
        )}[${data}]: ${getProps(
          i18n,
          'updateQstTips.pleaseSetSectionTxt',
        )} ${getProps(i18n, 'updateQstTips.topic_optionTxt')}`,
        'war',
      )
      break
    }
    case 'optContFail': {
      params.promptMsg(
        `${getProps(
          i18n,
          'updateQstTips.front_topic_txt',
        )}[${data}]: ${getProps(
          i18n,
          'updateQstTips.pleaseSetSectionTxt',
        )} ${getProps(i18n, 'updateQstTips.topic_optionTxt')}`,
        'war',
      )
      break
    }
    case 'optAnswerFail': {
      params.promptMsg(
        `${getProps(
          i18n,
          'updateQstTips.front_topic_txt',
        )}[${data}]: ${getProps(
          i18n,
          'updateQstTips.pleaseSetSectionTxt',
        )} ${getProps(i18n, 'updateQstTips.topic_answerTxt')}`,
        'war',
      )
      break
    }
    case 'analysisFail': {
      params.promptMsg(
        `${getProps(
          i18n,
          'updateQstTips.front_topic_txt',
        )}[${data}]: ${getProps(
          i18n,
          'updateQstTips.pleaseSetSectionTxt',
        )} ${getProps(i18n, 'updateQstTips.topic_analysisTxt')}`,
        'war',
      )
      break
    }
    case 'knowledgeFail': {
      params.promptMsg(
        `${getProps(
          i18n,
          'updateQstTips.front_topic_txt',
        )}[${data}]: ${getProps(
          i18n,
          'updateQstTips.pleaseSetSectionKnowlEdge',
        )} ${getProps(i18n, 'updateQstTips.topic_knowledge')}`,
        'war',
      )
      break
    }
  }
}

export const validateQuestion = async (question, originImport = false) => {
  let state = true
  const listFail = {
    listTitleFail: {
      type: 'titleFail',
      value: [],
    },
    listContentFail: {
      type: 'contentFail',
      value: [],
    },
    listOptContFail: {
      type: 'optContFail',
      value: [],
    },
    listOptAnswerFail: {
      type: 'optAnswerFail',
      value: [],
    },
    listAnalysisFail: {
      type: 'analysisFail',
      value: [],
    },
    listKnowlEdgeFail: {
      type: 'knowledgeFail',
      value: [],
    },
  }

  //check func return true if question fail
  question.forEach((item, index) => {
    //check title
    if (checkTitle(item)) {
      state = false
      listFail.listTitleFail.value.push(index + 1)
      return false
    }

    // check question and answer
    if (checkOptContent(item)) {
      state = false
      listFail.listOptContFail.value.push(index + 1)
      return false
    } else if (checkAnswer(item)) {
      state = false
      listFail.listOptAnswerFail.value.push(index + 1)
      return false
    }

    //check analysis
    if (checkAnalysis(item, originImport)) {
      state = false
      listFail.listAnalysisFail.value.push(index + 1)
      return false
    }

    //check analysis
    if (checkKnowlEdge(item)) {
      state = false
      listFail.listKnowlEdgeFail.value.push(index + 1)
      return false
    }
    //validate synthesize question
    if (item.type === 'synthesize') {
      if (
        (item.questionContent.questionList &&
          !item.questionContent.questionList.length) ||
        !item.questionContent.questionList
      ) {
        listFail.listContentFail.value.push(index + 1)
        return false
      } else {
        item.questionContent.questionList.forEach((question, i) => {
          // if (checkTitle({...question, statementCurrent: item.statementCurrent})) {
          //   state = false
          //   listFail.listTitleFail.value.push(`${index + 1}.${i + 1}`)
          //   return false
          // }
          if (checkOptContent(question)) {
            state = false
            listFail.listOptAnswerFail.value.push(`${index + 1}.${i + 1}`)
            return false
          }
          if (checkAnalysis(question, originImport)) {
            state = false
            listFail.listOptAnswerFail.value.push(`${index + 1}.${i + 1}`)
            return false
          }
        })
      }
    }
  })
  const timer = (ms) => new Promise((res) => setTimeout(res, ms))
  for (let property in listFail) {
    if (listFail[property].value.length) {
      showFailMessage(listFail[property].value, listFail[property].type)
    }
    await timer(0)
  }

  return state
}

// VALIDATE CK BLANK QUESTION
export const validateBlankQuestion = (question) => {
  if (!question.content) return true
  const blankQstContent = JSON.parse(question.content)
  console.log(blankQstContent)
  let valid = true
  // blankQstContent.blanksOptList.forEach(item => (if (item.inValid)))
  for (const item of blankQstContent.blanksOptList) {
    if (item.inValid) {
      valid = false
      $.promptMsg('Quá kí tự cho phép', 'war')
    }
    break
  }
  return valid
}
