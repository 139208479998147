/* eslint-disable no-undef */
const schoolService = {
  findSyId: async () => {
    const res = await $.Axios({
      url: '/admin/sy/findTeacherSchoolYearList?' + encrypt.encryptUrlData(),
    })
    if (res?.ok && res.entityList) {
      return res.entityList.find((x) => x.currentYear == 1)?.syId
    }

    return ''
  },
  findSyList: async (schoolId) => {
    try {
      const res = await $.Axios({
        url:
          '/admin/sy/findSchoolYearList?' +
          encrypt.encryptGetSign({}, { schoolId }),
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify({ schoolId }),
        transformRequest(data) {
          return data
        },
      })

      if (res.ok) {
        return res.entityList.map((item) => {
          item.showTimeStr = `${item.startYear}-${item.endYear}`
          return item
        })
      }
      return []
    } catch (e) {
      console.log(e)
      return []
    }
  },
  findSchoolById: async (schoolId) => {
    try {
      return $.Axios({
        url:
          '/admin/school/findSchoolById?' +
          encrypt.encryptUrlData({ schoolId, areaData: true }),
      })
    } catch (error) {
      console.log('findSchoolById error', error)
    }
  },
}

export default schoolService
