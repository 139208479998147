// * VUEJS
import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index' // 路由
import store from './store' // vuex

// * LANGUAGES
import langConfig from './common/lang/langConfig'

//* ELEMENT UI PLUS
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

// * FONT, CSS
import './assets/libs/iconfont/iconfont.css'
import './assets/libs/fontLib/font.css'
import 'element-plus/dist/index.css'
import '../src/assets/css/common.scss'

// * GLOBAL COMPS
import NewCard from './components/tabbar/UserCard.vue'

// * UTILS
import 'dayjs/locale/zh-cn'
import { loadingFunc, tooltipFunc } from './utils/app.utils'
import createDOMPurify from 'dompurify'

// * import global js
import axios from 'axios'
import commonJS from './common/common.js'
import encrypt from './common/encrypt.js'
import md5 from 'js-md5'
import QueryString from 'qs'
import dayjs from 'dayjs'

//* mixin
import globalMixin from './mixins/global.mixin'

// * Emitter
import mitt from 'mitt'

//* configs axios
axios.defaults.transformRequest = [
  function (data) {
    return qs.stringify(data)
  },
]

// * global varibales
window.$ = commonJS
window.encrypt = encrypt
window.md5 = md5
window.axios = axios
window.qs = QueryString
window.dayjs = dayjs

// ** APP
const app = createApp(App)
axios.defaults.baseURL = envs.VUE_APP_BASE_URL
app.config.globalProperties.$resourceUrl = envs.VUE_APP_RESOURCE_URL
app.config.globalProperties.$frontUrl = envs.VUE_APP_FRONT_URL
app.config.globalProperties.$uPdfUrl = envs.VUE_APP_UPLOAD_PDF_URL

app.config.globalProperties.emitter = mitt()

app.use(langConfig.langPacket)
app.use(store)
app.use(router)
app.use(ElementPlus)

app.mixin(globalMixin)

app.component('NewCard', NewCard)
app.directive('useLoading', (el, binding) => {
  loadingFunc(el, binding)
})
app.directive('useTooltip', (el, binding) => {
  tooltipFunc(el, binding)
})
const DOMPurify = createDOMPurify(window)
const dompurifyConfigs = {
  ADD_ATTR: ['linebreak'],
  ADD_TAGS: ['semantics', 'annotation'],
}
app.directive('sanitize', {
  created: (el, binding) =>
    (el.innerHTML = DOMPurify.sanitize(binding.value, dompurifyConfigs)),
  updated: (el, binding) => {
    if (binding.value != binding.oldValue)
      el.innerHTML = DOMPurify.sanitize(binding.value, dompurifyConfigs)
  },
})
app.mount('#app')
