export default {
  state: {
    classTabType: '',
  },
  mutations: {
    setClassTabType(state, type) {
      state.classTabType = type
    },
  },
}
