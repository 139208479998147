export default {
  headerTxt: {
    maximize: 'Chế độ toàn màn hình',
    reduction: 'Thoát chế độ toàn màn hình',
    personal: 'Tôi',
    logout: 'Thoát',
    updatePwd: 'Đổi mật khẩu',
    pointMe: 'Chọn tôi',
    oldPassword: 'Mật khẩu cũ',
    newPassword: 'Mật khẩu mới',
    personalInfo: 'Thông tin cá nhân',
    termsService: 'Điều khoản dịch vụ',
    message: 'Thông báo',
    notify: 'Thông báo',
  },

  loginTxt: {
    title: 'Đăng nhập',
    loginBtn: 'Đăng nhập',
    verTxt: {
      userName: 'Nhập tên người dùng',
      userLengthLimt: 'Độ dài tên người dùng từ 6 đến 100 ký tự',
      password: 'Nhập mật khẩu',
      lengthLimt: 'Độ dài mật khẩu từ 6 ~ 32 ký tự',
    },
    rememberPassword: 'Duy trì đăng nhập',
    sliderText: 'Kéo để hoàn thành bức tranh',
    clickRefresh: 'Ấn để tạo mới ảnh',
    rememberPasswordTxt:
      'Vui lòng liên hệ với Admin trường để được đặt lại mật khẩu',
    hotlineFschoolTxt: 'Hotline Fschool:  0838888966',
    forgetPassword: 'Quên mật khẩu',
    inputAccountNum: 'Nhập tên đăng nhập',
  },

  placeholder: {
    userName: 'Nhập tên người dùng',
    password: 'Nhập mật khẩu',
  },

  tips: {
    login: 'Đăng nhập thành công！',
    sucTitle: 'Thành công',
    errTitle: 'Lỗi',
    warTitle: 'Cảnh báo',
    infoTitle: 'Tin nhắn',
    pleaseSteNum: 'Hãy điền đầy đủ điểm của mỗi câu',
    prompt: 'Xác nhận xóa?',
    inputCatalogueName: 'Tên nhóm bài tập không thể để trống',
    inputIntTips: 'Người dùng nhập số nguyên dương tại Thời gian làm bài',
    showExamDetail: 'Xem chi tiết đề thi',
    printExam: 'In đề thi',
    editExam: 'Sửa đề thi',
    deleteExam: 'Xóa đề thi',
    copy: 'Sao chép',
    changePass: 'Đổi mật khẩu',
    emptyMultipleTips: 'Chọn ít nhất 1 đáp án đúng',
    emptyBlankTips: 'Nhập đáp án cho câu hỏi điền từ',
    saveSuccess: 'Lưu thành công',
    pleaseSetTime: 'Hãy điền thời gian cho phép làm bài',
    sameValueTIme: 'Thời gian bắt đầu và kết thúc không hợp lệ',
    subjectFail:
      'Thầy / Cô đang thực hiện {2} tập môn <strong>{0}</strong>{1}. Thầy / Cô có chắc chắn muốn tiếp tục?',
    forClass: ' cho lớp <strong>{0}</strong>',
    successTitle: 'thành công',
    note: 'Lưu ý',
    save: 'lưu',
    distributeTask: 'giao bài tập',
    distributeTaskSuccess: 'Giao bài thành công',
    fileTypeError: 'Vui lòng tải lên file với định dạng PDF/PNG/JPG/JPEG',
  },

  mainTxt: {
    dashboard: 'Tổng quan',
  },

  publicTxt: {
    stopEditing: 'Dừng sửa',
    search: 'Nhập tên đề thi',
    filter: 'Bộ lọc',
    empty: 'Không có dữ liệu！',
    state: 'Trạng thái',
    provinceCity: 'Tỉnh/TP',
    areaCounty: 'Quận/Huyện',
    addrs: 'Địa điểm',
    contactInfo: 'Thông tin liên hệ',
    operate: 'Thao tác',
    type: 'Loại',
    detailsTxt: 'Chi tiết',
    cancelBtnTxt: 'Hủy',
    confirmBtnTxt: 'Xác nhận',
    conserveBtnTxt: 'Lưu',
    downloadExcelBtn: 'Export dữ liệu',
    downloadExcelTemplateBtn: ' Tải file Excel mẫu',
    uploadExcelBtn: ' Tải lên bằng file Excel',
    updateDate: 'Ngày chỉnh sửa',
    activeTxt: 'Kích hoạt',
    notActiveTxt: 'Chưa kích hoạt',
    confirmDelTxt: 'Xác nhận xóa',
    confirmDelTxt2:
      'Bạn chắc chắn muốn chuyển trạng thái thành chưa kích hoạt và xóa trường?',
    agreeTxt: 'Đồng ý',
    resetTxt: 'Đặt lại',
    addTxt: 'Thêm',
    fileAnalysis: 'Đang tải tài liệu...',
    homePage: 'Tổng quan',
    createTime: 'Thời gian tạo',
    verTxt: {
      numberOrLetter: 'Nhập số hoặc chữ cái',
    },
    importExcel: 'Nhập vào file Excel',
    selectFile: 'Chọn tài liệu',
    notSelectFile: 'Chưa chọn tài liệu nào',
    upload: 'Tải lên',
    record: 'bản ghi',
    uploadError: 'Tải lên thất bại',
    lookErrorRecord: 'Kiểm tra bản ghi lỗi',
    delete: 'Xóa',
    screen: 'Lọc',
    delAll: 'Xóa toàn bộ',
    confirm: 'Xác nhận',
    errorInfo: 'Thông tin lỗi',
    createPerson: 'Người tạo',
    closeBtn: ' Đóng',
    edit: 'Sửa',
    numOrder: 'STT',
    total: 'Tổng số',
    note: 'Ghi chú',
    noneAtPresent: 'Chưa có',
    addAll: 'Thêm tất cả',
    choiceAll: 'Chọn tất cả',
    watch: 'xem',
    record_correct: 'bản ghi',
    save: 'Lưu',
    listView: 'Hiển thị dạng danh sách',
    girdView: 'Hiển thị dạng lưới',
    updateAccount: 'Sửa tài khoản',
    updatePass: 'Đổi mật khẩu',
    removeAccount: 'Xóa tài khoản',
    renameRole: 'Sửa tên vai trò',
    roleDelegation: 'Phân quyền vai trò',
    removeRole: 'Xóa vai trò',
    viewExamPractise: 'Xem chi tiết đề ôn luyện',
    removeExamPractise: 'Xóa đề ôn luyện',
    areYouSure: 'Bạn có chắc chắn không?',
  },

  schoolTxt: {
    title: 'Quản lý tổ chức',
    addBtn: 'Thêm',
    currentSchoolList: 'Danh sách trường hiện tại',
    delSchoolList: 'Danh sách trường đã bị xóa ',
    schoolName: 'Tên trường',
    // schoolId: 'Mã trường',
    schoolLevel: 'Cấp trường',
    nature: 'Loại hình',
    schoolSort: 'Loại trường',
    // schoolNameAndId: 'Nhập tên trường và ID trường',
    inputSchoolName: 'Nhập tên trường',
    addSchoolTitle: 'Thêm trường',
    updateSchoolTitle: 'Cập nhật trường học',
    repName: 'Tên người đại diện',
    repMobile: 'Số điện thoại người đại diện',
    repEMail: 'Email  người đại diện',
    contactName: 'Tên người liên hệ',
    contactMobile: 'Số điện thoại người liên hệ',
    schoolBranchInfo: 'Thông tin của trường và điểm trường',
    classList: 'Danh sách lớp',
    schoolAccountList: 'Danh sách tài khoản trường học',
    backList: 'Quay lại danh sách trường học',
    // synchroSchool: 'Đồng bộ thông tin trường học',
    schoolInfo: 'Thông tin trường học',
    branchList: 'Danh sách điểm trường',
    schoolBranchName: 'Tên điểm trường',
    schoolBranchId: 'Mã điểm trường',
    schoolBranchAddr: 'Địa chỉ điểm trường',
    addBranchTitle: 'Thêm điểm trường',
    updateBranchTitle: 'Cập nhật điểm trường',
    delSchool: 'Xóa trường học',
    recoverySchool: 'Khôi phục trường học',
    recoverySchoolTxt: 'Bạn chắc chắn muốn khôi phục trường học này?',
    delBranchSchool: 'Xóa điểm trường',
    delBranchSchoolTxt: 'Bạn chắc chắn muốn xóa trường học này?',
    verTxt: {
      provincesCityId: 'Chọn Tỉnh/TP',
      regionId: 'Chọn Quận/Huyện',
      level: 'Chọn cấp trường',
      nature: 'Chọn loại hình',
      type: 'Chọn loại trường',
      schoolName: 'Chọn trường',
      address: 'Nhập địa điểm',
      deputyUserName: 'Nhập tên  người đại diện',
      deputyUserTel: 'Nhập số điện thoại người đại diện',
      schoolBranchName: 'Nhập tên điểm trường học',
      schoolBranchId: 'Nhập ID điểm trường ',
    },
  },

  classTxt: {
    inputClassNmae: 'Nhập tên lớp',
    grade: 'Khối',
    // synchroClass: 'Đồng bộ thông tin lớp',
    className: 'Tên lớp',
    classId: 'Mã lớp',
    addClassTitle: 'Thêm lớp',
    updateClassTitle: 'Cập nhật lớp',
    teacherListTxt: 'Danh sách giáo viên',
    studentListTxt: 'Danh sách học sinh',
    verTxt: {
      className: 'Nhập tên lớp',
      className_l_l: 'Độ dài tên lớp từ 1 ~ 10 ký tự',
      classId: 'Nhập mã lớp',
      classId_l_l: 'Độ dài mã lớp từ 1 ~ 10 ký tự',
      grade: 'Chọn khối',
      teacherId: 'Chọn giáo viên',
      subjectId: 'Chọn môn',
      moveToClass: 'Chọn lớp muốn chuyển đến',
    },
    delClass: 'Xóa lớp học',
    delClassTxt: 'Bạn chắc chắn muốn xóa lớp không',
    distributeTeacher: 'Gán giáo viên ',
    cutClass: 'Đổi lớp',
    subject: 'Môn',
    studentName: 'Tên học sinh',
    updateDistributeTxt: 'Thay đổi thông tin giáo viên',
    distribute: 'Gán',
    otherSbject: 'Môn khác',
    removeTeacher: 'Xóa giáo viên',
    removeTeacherTxt: 'Bạn chắc chắn muốn xóa giáo viên này không',
    selectAll: 'Chọn toàn bộ',
    changeClass: 'Đổi lớp',
    moveToClass: 'Lớp chuyển đến',
    moveTo: 'Chuyển lớp',
    schoolYear: 'Năm học',
    addSchoolYear: 'Thêm năm học mới',
    oldSchoolYear: 'Năm học cũ',
    newSchoolYear: 'Năm học mới',
    currentSchoolYear: 'Năm học hiện tại',
    activationSchoolYear: 'Kích hoạt năm học mới',
    activationTipsFront: 'Bạn xác nhận muốn kích hoạt năm học mới',
    activationTipsAfter:
      'Kích hoạt thành năm học mới, thao tác này không thể thu hồi.',
    understandAndContinue: 'Đã hiểu và tiếp tục',
    subjectAll: 'Tất cả môn',
    classTotal: 'Sĩ số lớp',
    teacherTotal: 'Số bài chưa nộp',
    classTask: 'Bài tập lớp',
    classText: 'Lớp học',
    shortClassTxt: 'Lớp',
    classInfoText: 'Thông tin',
    distributionTask: 'Bài tập đã giao',
    noScoreTask: 'Số bài chưa chấm',
    nameSelectTxt: 'Tìm theo tên',
    downloadList: 'Tải danh sách',
    backClassHome: ' Về trang chủ',
    classManage: 'Quản lý lớp học',
    distributionExcercise: 'Bài tập đã giao',
    noScoreExcercise: 'Số bài chưa chấm',
    loadingTxt: 'Đang tải...',
    baselineTxt: 'Không còn nữa',
    assignTeachersBySubject: 'Gán giáo viên theo môn',
    selectedTeacher: 'Chọn giáo viên',
    selectedSubject: 'Chọn môn',
    selectedClass: 'Chọn lớp học',
    searchTeacherTxt: 'Tìm kiếm theo tên giáo viên',
    subjectTxt: 'Môn học',
    classTxt: 'Lớp học',
    assignmentResults: 'Kết quả phân công',
    assignmentError: 'Gán giáo viên lỗi',
    upStaire: 'Lên lớp',
    repetition: 'Lưu ban',
    nextClass: 'Lớp chuyển lên',
    noNextClass: 'Không có lớp chuyển lên',
    repetitionClass: 'Lớp lưu ban',
    notRepetition: 'Không lưu ban',
    graduate: 'Ra trường',
    levelUpConfirmTxt:
      'Bạn có chắc chắn muốn thực hiện việc lên lớp/lưu ban không?',
  },

  accountTxt: {
    controller: 'Quản trị viên',
    teacherTxt: 'Giáo viên',
    studentTxt: 'Học sinh',
    addController: 'Thêm quản trị viên',
    activeTxt: 'Hoạt động',
    notActiveTxt: 'Không hoạt động',
    addControllerTxt: 'Thêm tài khoản quản trị viên',
    updateControllerTxt: 'Sửa tài khoản quản trị viên',
    updateControllerState: 'Thay đổi trạng thái của quản trị viên',
    updateControllerStateTxt: 'Bạn chắc chắn muốn đổi trạng thái không？',
    // updateControllerStateTxt: 'Bạn chắc chắn muốn đổi trạng thái thành không hoạt động không？',
    delController: 'Xóa Giáo viên',
    delControllerTxt: 'Bạn chắc chắn muốn xóa giáo viên này không?',
    updatePasswor: 'Đổi mật khẩu',
    verTxt: {
      userName: 'Nhập tên người dùng',
      userName_l_l: 'Độ dài tên người dùng từ 6 ~ 32 ký tự',
      password: 'Nhập mật khẩu',
      password_l_l: 'Độ dài mật khẩu từ 6 ~ 32 ký tự',
      confirmPwd: 'Nhập lại mật khẩu',
      atypism: 'Mật khẩu không trùng khớp',
      name: 'Nhập tên hoặc mã định danh',
      userKey: 'Nhập mã học sinh',
      classId: 'Chọn lớp',
      school: 'Chọn trường',
      syId: 'Hãy chọn năm học',
    },
    userNameTxt: 'Tên đăng nhập',
    teacherId: 'Mã định danh',
    inputTeacherId: 'Nhập mã giáo viên',
    addTeacherTxt: 'Thêm tài khoản giáo viên',
    updateTeacherTxt: 'Sửa tài khoản giáo viên',
    updateTeacherState: 'Thay đổi trạng thái giáo viên',
    updateTeacherStateTxt: 'Bạn chắc chắn muốn đổi trạng thái không？',
    // updateTeacherStateTxt: 'Bạn chắc chắn muốn đổi trạng thái thành hoạt động không？',
    delTeacher: 'Xóa giáo viên',
    delTeacherTxt: 'Bạn chắc chắn muốn xóa giáo viên này không？ ',
    studentId: 'Mã định danh',
    inputStudentId: 'Nhập mã học sinh',
    birthday: 'Ngày tháng năm sinh',
    shortBirthDay: 'Ngày sinh',
    gender: 'Giới tính',
    men: 'Nam',
    women: 'Nữ',
    addStudentTxt: 'Thêm tài khoản học sinh',
    updateStudentTxt: 'Sửa tài khoản học sinh',
    updateStudentState: 'Thay đổi trạng thái học sinh',
    updateStudentStateTxt: 'Bạn chắc chắn muốn thay đổi trạng thái không？',
    delStudentTxt: 'Bạn chắc chắn muốn xóa học sinh này không？',
    delStudent: 'Xóa học sinh',
    teacherName: 'Tên giáo viên',
  },

  userTxt: {
    userTxt: 'Người dùng',
    title: 'Quản lý người dùng',
    accountManage: 'Quản lý tài khoản',
    administrators: 'Quản trị viên',
    roleManage: 'Quản lý vai trò',
    addBtn: 'Tạo',
    inputName: 'Nhập tên hoặc mã định danh',
    inputUserNmae: 'Nhập tên đăng nhập',
    role: 'Vai trò',
    name: 'Họ và tên',
    userName: 'Tên đăng nhập',
    roleName: 'Tên vai trò',
    accountNum: 'Số tài khoản được gán vai trò',
    addMaskTitle: 'Thêm tài khoản Admin',
    updateMaskTitle: 'Sửa tài khoản Admin',
    passwordTxt: 'Mật khẩu',
    confirmPwdTxt: 'Xác nhận mật khẩu',
    canVisitSchool: 'Trường học có thể truy cập',
    addRoleTitle: 'Thêm tên vai trò',
    updateRoleTitle: 'Sửa tên vai trò',
    email: 'Email',
    mobile: 'Số điện thoại',
    allTxt: 'Toàn bộ',
    partTxt: '1 phần',
    delUser: 'Xóa tài khoản Admin',
    delUserTxt: 'Bạn chắc chắn muốn xóa quản trị viên này không？',
    delRole: 'Xóa vai trò',
    delRoleTxt: 'Bạn chắc chắn muốn xóa vai trò này không？',
    updateUserState: 'Thay đổi trạng thái Admin',
    updateUserStateTxt: 'Bạn chắc chắn muốn đổi trạng thái không？',
    updateUserStateTxt_y: 'Bạn xác nhận muốn đổi trạng thái thành kích hoạt?',
    updateUserStateTxt_n: 'Bạn xác nhận đổi trạng thái thành chưa kích hoạt?',
    verTxt: {
      roleName: 'Nhập tên vai trò',
      roleName_l_l: 'Độ dài tên vai trò từ 1 ~ 32 ký tự',
    },
    find: 'Tìm thấy',
    aResult: 'Kết quả',
    pwdTxt: 'Mật khẩu',
    studentInfo: 'Thông tin học sinh',
    parentInof: 'Thông tin phụ huynh',
    parentName: 'Tên phụ huynh',
    parentMobile: 'Số điện thoại phụ huynh',
    parentEmail: 'Email của phụ huynh',
    confirmEditStudentInfo: 'Xác nhận sửa thông tin học sinh này',
    confirmEditStudentInfoTxt: 'Bạn xác nhận muốn sửa thông tin học sinh này？',
    addQuestionBtn: 'Tạo câu hỏi',
    inputSearchName: 'Nhập tên',
    scheduleManage: 'Quản lý thời khóa biểu',
  },

  questionTxt: {
    warEmptyKnowledge: 'Chưa chọn cây kiến thức cho câu hỏi',
    pinQuestion: 'Ghim câu hỏi',
    undefined: 'Chưa xác định',
    categoryTxt: 'Loại đề',
    questionBank: 'Ngân hàng câu hỏi',
    topicContent: 'Nội dung câu hỏi',
    difficulty: 'Độ khó',
    special: 'Chuyên đề',
    theme: 'Chủ đề',
    knowledgePoints: 'Mạch kiến thức',
    analysis: 'Giải thích',
    venogram: 'Mạch',
    content: 'Nội dung',
    infof: 'Thông tin',
    commonlyInfo: 'Thông tin chung',
    detailedInfo: 'Thông tin chi tiết',
    questionTypeTxt: 'Dạng câu hỏi',
    syncTime: 'Thời gian đồng bộ',
    fSchoolQuestionBank: 'Ngân hàng Fschool',
    schoolQuestionBank: 'Ngân hàng trường',
    manualCreate: 'Tạo thủ công',
    wordImport: 'Tạo hàng loạt bằng file Word',
    choose: 'Chọn từ ngân hàng Fschool',
    createQuestionTitle: 'Tạo câu hỏi',
    uploadFile: 'Tải lên',
    downloadFile: 'Tải xuống',
    batchImportQuestion: 'Nhập hàng loạt câu hỏi',
    knowledgeField: 'Trường kiến thức',
    addKnowledgeField: 'Thêm trường kiến thức',
    editQuestionTitle: 'Soạn câu hỏi',
    updateQuestionTitle: 'Cập nhật câu hỏi',
    preview: 'Xem trước',
    conserveAndEditOther: 'Lưu và soạn câu khác',
    questionTitle: 'Câu hỏi',
    addAnswer: 'Thêm đáp án',
    rightKey: 'Đáp án đúng',
    addBlankBtn: 'Thêm ô điền vào chỗ trống',
    problem: 'Câu hỏi',
    delQuestion: 'Xóa câu hỏi?',
    delQuestionTxt:
      'Bạn chắc chắn muốn xóa câu hỏi này khỏi ngân hàng đề thi ? (Câu hỏi bị xóa trong ngân hàng câu hỏi cũng sẽ bị xóa trong các đề thi, câu hỏi trong đề thi đã giao sẽ không bị xóa)',
    fileParsing: 'Đang xử lý ...',
    subjectType: 'Dạng câu hỏi',
    success_num_front: 'Bạn đã thêm',
    success_num_after: 'câu hỏi',
    addQuestion: 'Thêm câu hỏi',
    difficultyAll: 'Tất cả độ khó',
    upToTenOptions: 'Nhiều nhất có thể có 10 lựa chọn',
    personalQuestionBank: 'Kho cá nhân',
    deleteAnswerTxt: 'Bạn chắc chắn muốn xóa đáp án này không?',
    viewDetailQuestion: 'Xem chi tiết câu hỏi',
    editQuestion: 'Sửa câu hỏi',
    deleteDetailQuestion: 'Xóa câu hỏi',
    addedQuestionToPersonalQuestionBank: 'Thêm vào ngân hàng Trường',
    notInOtherGrade: 'Câu hỏi này hiện tại không được gán với khối nào khác.',
    inOtherGrade: 'Câu hỏi này cũng được gán với khối: ',
    view: 'Xem',
    addedQuestion: 'Đã Thêm',
    addedQuestionToPersonal: 'Thêm vào kho cá nhân',
    addedQuestionToTeacherBank: 'Thêm vào Kho GV',
    categoryExam: 'Danh mục đề thi',
    successNum: 'Bạn đã thêm {0} câu hỏi thành công',
    singleChoiceQuestions: 'Trắc nghiệm 1 đáp án',
    shortQuestions: 'Tự luận',
    command: 'Câu lệnh',
    commandContent: 'Nội dung câu lệnh',
    questionType: 'Loại câu hỏi',
    actionChoise: 'Chọn',
    searchByContent: 'Tìm theo nội dung',
    search: 'Tìm kiếm',
    commandEdit: 'Sửa câu lệnh',
    emptyCommandTxt:
      'Chưa có câu lệnh, click vào dấu "..." bên phải để chỉnh sửa',
    addCommandTop: 'Thêm câu lệnh ở trên',
    applyTo: 'Áp dụng đến câu',
    addDescription: 'Thêm mô tả trên đầu',
    descriptionContent: 'Nội dung mô tả',
    removeCommand: 'Xóa câu lệnh?',
    removeCommandDesc: 'Bạn có chắc chắn muốn xóa câu lệnh?',
    errorCountSynthesize:
      'Dạng đề bài nhiều câu hỏi là phải có ít nhất 2 câu hỏi trong 1 đề bài',
    rangeApply: 'từ câu {0} đến câu {1}',
    questionApply: 'Câu số {0}',
    selectQuestion: 'Chọn câu hỏi',
    addQuestionFromBank: 'Thêm từ ngân hàng câu hỏi',
    highlightContent:
      'Lưu ý: Câu lệnh có thể đang được gộp vào đề bài. Chọn <strong>Sửa câu hỏi</strong> để <strong>xóa</strong> hoặc <strong>tách riêng</strong> câu lệnh nếu cần.',
  },

  import: {
    importTips_1:
      'Sau khi xử lý tệp, các công thức liên quan sẽ được chuyển thành hình ảnh',
    importTips_2: 'Quy tắc đầu vào cho các câu hỏi:',
    importTips_3:
      'Phần 1: Đó là đề bài chung (nếu có) chỉ có với loại câu hỏi “Một đề bài nhiều câu hỏi” ',
    importTips_4:
      'Phần 2: Là đề bài của câu hỏi (Là đề bài của từng câu hỏi nhỏ đối với loại câu hỏi “Một đề bài nhiều câu hỏi”',
    importTips_5:
      'Phần 3: Đó là nội dung các câu trả lời. Đầu các tùy chọn phải bắt đầu bằng [A, -J,] và theo thứ tự và liên tục. Các tùy chọn có thể  hình ảnh, công thức, v.v',
    importTips_6:
      'Là Đáp án đúng cho câu hỏi lựa chọn, câu trả lời phải là số thứ tự (AJ) của mỗi lựa chọn hoặc câu trả lời ngay sau phần đầu của "Đáp án:". Đối với câu hỏi điền vào chỗ trống thì trước mỗi câu trả lời cho chỗ trống phải có {n}. n tương ứng là số tương ứng với chỗ trống',
    importTips_7:
      'Phần 5: Là phần giải thích đáp án, phần nội dung giải thích đặt ngay sau "Gỉải thích" ',
    importTips_8:
      'Phần 6:Là Loại câu hỏi của câu hỏi, loại câu hỏi phải bắt đầu bằng "Loại câu hỏi:", loại câu hỏi được chia thành 5 loại (Chọn 1 đáp án, chọn nhiều đáp án, tự luận, điền vào chỗ trống, một đề nhiều câu hỏi.',
    importTips_9:
      'Độ khó của đề thi, độ khó phải bắt đầu bằng "Độ khó:", và độ khó được chia thành 4 mức độ (Nhận biết, Tinh thông, Vận dụng, Vận dụng cao).',
    importTips_10:
      'Chú ý: Các phương án trong câu hỏi kiểm tra phải lớn hơn hoặc bằng 2 phương án, nếu chỉ có 2 phương án là đúng hoặc sai, nếu chỉ có một phương án trả lời và nhiều hơn 2 phương án thì đó là một lựa chọn duy nhất. Có nhiều câu trả lời và nhiều hơn 2 lựa chọn, nó là một câu hỏi nhiều lựa chọn.',
    importTips_11:
      '3. Phải có các ký tự trước và sau khi chèn vùng ảnh, có thể là văn bản hoặc dấu câu.',
  },

  examTxt: {
    minuteNum: '{0} phút',
    unlimited: 'Không giới hạn',
    beingUpdated: 'Tính năng này đang được cập nhật',
    testPaper: 'Ngân hàng đề thi',
    testPaperBank: 'Ngân hàng đề thi',
    inputTestPaperName: 'Nhập tên đề thi',
    topicQuantity: 'Số câu',
    time_min: 'Thời gian (phút)',
    topicType: 'Dạng đề',
    release: 'Xuất bản',
    place: 'Địa điểm',
    conversionTestPaper: 'Đề thi chuyển cấp',
    courseTestPaper: 'Đề thi theo chương trình học',
    contestTestPaper: 'Đề thi học sinh giỏi, đề thi Olympic ',
    createExamTxt: 'Tạo đề thi',
    createOtherExamTxt: 'Tạo đề thi khác',
    showAnswer: 'Hiển thị đáp án',
    order: 'STT',
    answer: 'Đáp án',
    answerContent: 'Nội dung đáp án',
    uploadAnswer: 'Đề tự luận - Tải lên đáp án',
    rightAnswer: 'Đáp án đúng',
    explain: 'Giải thích',
    examToClass: 'CHỌN LỚP GIAO BÀI KIỂM TRA NÀY',
    mixExam: 'ĐẢO ĐỀ',
    mixQuestions: 'Đảo thứ tự câu',
    mixAnswers: 'Đảo thứ tự đáp án',
    hotPaper: 'Đề thi hot',
    newPaper: 'Đề thi mới',
    addPaperBtn: 'Tạo đề thi',
    delPaper: 'Xóa đề thi',
    delPaperTxt: 'Đề thi sẽ được xóa hoàn toàn khỏi ngân hàng đề thi',
    manualAddBtn: 'Tạo đề thi từ ngân hàng câu hỏi',
    manualAddTxt:
      'Tạo đề thi bằng cách chọn thủ công các câu hỏi có sẵn trong ngân hàng câu hỏi',
    structureAddBtn: 'Tạo đề thi từ ma trận đề',
    structureAddTxt:
      ' Tạo đề thi từ các câu hỏi có sẵn trong ngân hàng câu hỏi theo ma trận đề ',
    structureTypeOfAnswerTxt:
      'Câu hỏi một đáp án có viền tròn, nhiều đáp án có viền vuông',
    computerAddBtn: 'Chọn từ máy tính',
    computerAddTxt: 'Tạo đề thi bằng file PDF hoặc hình ảnh có sẵn trong máy',
    meansAddBtn: 'Chọn từ kho học liệu',
    meansAddTxt: 'Tạo đề thi từ kho đề Fschool/Trường học',
    fromStructureCreate: 'Tạo từ ma trận đề thi',
    fromComputerCreate: 'Chọn từ máy tính',
    paper: 'Đề thi',
    excercise: 'Bài tập',
    updatePaper: 'Sửa đề thi',
    testPaperName: 'Tên đề thi',
    testDescribe: 'Mô tả đề thi',
    createOtherExam: 'Tạo đề khác',
    inputTestDescribe: 'Nhập mô tả đề thi',
    inputTestDescribeExcercise: 'Nhập mô tả bài tập',
    timeMin: 'Thời gian（Phút）',
    verTxt: {
      testPaperName: 'Hãy nhập tên đề thi',
      timeMin: 'Hãy nhập thời (phút)',
      inputInt: 'Hãy nhập số nguyên ',
    },
    minute: 'Phút',
    cancelChoice: 'Đã thêm',
    conservePaper: 'Lưu đề thi',
    addQuestionBtn: 'Thêm câu hỏi',
    scoring: 'Tính điểm',
    scoringSystem: 'Thang điểm',
    totalScoreTxt: 'Tổng điểm',
    questionNum: 'Số lượng câu hỏi',
    proportion: 'Tỉ lệ',
    statistics: 'Thống kê',
    score: 'điểm',
    uppercaseFirstLetterScore: 'Điểm',
    roundingPoint: 'Điểm làm tròn',
    move: 'Di chuyển',
    topic: 'Câu ',
    dragOrClickUpload: 'Kéo tệp hoặc click để tải lên',
    createPaperStructureAndKnowledge: 'Tạo ma trận đề thi và trường kiến thức',
    time: 'Thời gian',
    min: 'Phút',
    lookAndCheck: 'Xem',
    choice: 'Chọn',
    preserveCheckTxt: 'Lưu vào ngân hàng câu hỏi',
    selectRightAnswer: 'Hãy chọn đáp án đúng',
    inputAnswerTxt: 'Nhập đáp án',
    setBlankAnswer: 'Nhập đáp án cho câu hỏi điền vào chỗ trống',
    setBlankAnswerTips:
      'Nhập đáp án của câu hỏi. Ấn Enter để thêm đáp án. Nếu đáp án của học sinh khớp với đáp án ở dưới, hệ thống sẽ coi là trả lời đúng',
    blankFrame: 'Khung điền vào chỗ trống',
    fromTestPaperBankCreate: 'Tạo từ kho học liệu',
    createFromQuestionBankTxt: 'Tạo từ ngân hàng câu hỏi',
    topicRemoved: 'Câu hỏi đã bị xóa',
    topicAdded: 'Đã thêm câu hỏi thành công',
    uncheckQuestion: 'Đã bỏ chọn câu hỏi',
    dragUploadText:
      'Lưu ý: Hệ thống chỉ chấp nhận file có định dạng PDF, PNG, JPG, JPEG có kích thước tương đương khổ A4 và dung lượng không quá 5MB.',
    testPaperTxt: 'Đề thi',
    addToQuestionBank: 'Thêm vào NH',
    testPaperAll: 'Tất cả đề thi',
    personalTestPaper: 'Kho cá nhân',
    schoolTestPaper: 'Ngân hàng trường',
    manualAddBtnTask: 'Tạo Bài tập từ ngân hàng câu hỏi',
    manualAddTxtTask:
      'Tạo Bài tập bằng cách chọn thủ công các câu hỏi có sẵn trong ngân hàng câu hỏi',
    structureAddBtnTask: 'Tạo Bài tập từ ma trận đề',
    structureAddTxtTask:
      ' Tạo Bài tập từ các câu hỏi có sẵn trong ngân hàng câu hỏi theo ma trận đề ',
    config: 'Cấu hình',
  },

  structure: {
    paperStructure: 'Ma trận đề thi',
    createPaperStructure: 'Tạo ma trận đề thi',
    source: 'Nguồn',
    inputStructureName: 'Hãy nhập tên ma trận đề thi',
    synchronous: 'Đồng bộ',
    local: 'CMS ',
    continue: 'Tiếp tục',
    paperStructureName: 'Tên ma trận đề thi',
    paperStructureDescribe: 'Mô tả ma trận đề thi',
    verTxt: {
      inputStructureName: 'Hãy nhập tên ma trận đề thi',
    },
    backFirstStep: 'Trở về bước 1',
    variousSubjectsAverage: 'Số điểm mỗi câu',
    totalQuestionsNum: 'Số lượng câu hỏi',
    totalScore: 'Tổng điểm',
    total: 'Tổng',
    addPaperStructure: 'Thêm ma trận đề thi',
    conserveStructure: 'Lưu ma trận đề thi',
    delStructure: 'Xóa ma trận đề thi?',
    delStructureTxt:
      'Ma trận đề thi sẽ bị xóa vĩnh viễn. Các đề thi được tạo từ ma trận đề thi này sẽ không bị ảnh hưởng',
    describe: 'Mô tả',
    questionBankFschool: 'Ngân hàng câu hỏi Fschool',
    complete: 'Hoàn thành',
    addToBtn: 'Thêm',
    addedBtn: 'Đã thêm',
    schoolTxt: 'Trường học',
    fillCorrectlyAverageScore: 'Hãy nhập số điểm mỗi câu',
    selectQuestion: 'Chọn đề thi', // 1572 Bug 修改,
  },

  practise: {
    exercises: 'Đề ôn luyện',
    addTestPaper: 'Thêm đề thi',
    delPractise: 'Xóa đề ôn luyện?',
    delPractiseTxt: `Bạn xác nhận muốn xóa? \n (Đề luyện tập sẽ bị ẩn tại client Học sinh)`,
    paperLibrary: 'Ngân hàng đề thi',
    allTxt: 'Tất cả',
    published: 'Đã gửi',
    unpublished: 'Chưa gửi',
    allGradeTxt: 'Tất cả các khối',
    isPublish: 'Đã xuất bản',
    notPublish: 'Chưa xuất bản',
    createOtherPractice: 'Tạo bài tập khác',
    manualAddBtnTask: 'Tạo bài tập từ ngân hàng câu hỏi',
    manualAddTxtTask:
      'Tạo bài tập bằng cách chọn thủ công các câu hỏi có sẵn trong ngân hàng câu hỏi',
    computerAddBtn: 'Chọn từ máy tính',
    computerAddTxtTask:
      'Tạo đề thi bằng file PDF hoặc hình ảnh có sẵn trong máy',
    structureAddBtnTask: 'Tạo bài tập từ ma trận đề',
    structureAddTxtTask:
      'Tạo bài tập từ các câu hỏi có sẵn trong ngân hàng câu hỏi theo ma trận đề ',
    meansAddBtnTask: 'Chọn từ kho học liệu',
    meansAddTxtTask: 'Tạo bài tập từ kho đề Fschool/Trường học',
    savePractice: 'Lưu bài tập',
  },

  print: {
    common: 'Tổng',
    page: 'trang',
    questionTime: 'Thời gian làm bài',
    admissionNum: 'Số báo danh',
    multipleQuestion: 'Trắc nghiệm nhiều đáp án',
    fileGenerating: 'Đang tạo file',
    shortQuestion: 'Câu tự luận',
    blanksQuestion: 'Câu điền vào chỗ trống',
  },

  synthesize: {
    titleTxt: 'Đề bài chung',
    topicContent: 'Nội dung đề bài',
  },

  specialStructure: {
    variousSubjectsAverage1: 'Số điểm',
    variousSubjectsAverage2: 'mỗi câu',
    totalQuestionsNum1: 'Tổng',
    totalQuestionsNum2: 'số câu',
    totalScore1: 'Tổng',
    totalScore2: 'điểm',
  },

  updateQstTips: {
    setSingleCont: 'Hãy thiết lập nội dung câu trắc nghiệm một đáp án',
    inputOptCont: 'Hãy nhập nội dung các mục chọn',
    setSingleAnswer: 'Hãy thiết lập nội dung đáp án câu trắc nghiệm một đáp án',
    setMultipleCont: 'Hãy thiết lập nội dung câu trắc nghiệm nhiều đáp án',
    setMultipleAnswer: 'Hãy thiết lập từ 2 đáp án trở lên',
    setBlanksCont: 'Hãy thiết lập nội dung câu điền vào chỗ trống',
    inputAnswerCont: 'Hãy nhập nội dung đáp án',
    blanksErrTips: 'Số lượng đáp án và ô trống không trùng khớp',
    confirmDeleteOpt: 'Xác nhận xóa mục chọn này?',
    DeleteTheAnswer: 'Bạn chắc chắn muốn xóa câu hỏi này không?',
    setSyntCont: 'Hãy thiết lập nội dung câu nhiều câu hỏi nhỏ',
    front_topic_txt: 'Câu hỏi số',
    pleaseSetSectionTxt: 'Hãy thiết lập',
    topic_problemTxt: 'đề bài hoặc câu lệnh',
    topic_analysisTxt: 'giải thích',
    topic_optionTxt: 'mục chọn',
    topic_answerTxt: 'đáp án',
    inputAnalysis: 'Hãy nhập nội dung giải thích',
    topic_knowledge: 'kiến thức cho câu hỏi',
    pleaseSetSectionKnowlEdge: 'Chưa chọn mạch',
    inputTitleOrStatement: 'Hãy nhập đề bài hoặc câu lệnh',
  },

  task: {
    placeholderInputTask: 'Tìm theo ID hoặc Tên bài tập',
    unDelivered: 'Chưa giao',
    delivered: 'Đã giao',
    state: {
      all: 'Tất cả',
      unfinished: 'Chưa hoàn thành',
      complished: 'Đã hoàn thành',
    },
    mixExamTotal: 'Số đề đảo',
    taskTxt: 'Bài tập',
    draft: 'Nháp',
    published: 'Đã gửi',
    folder: 'Nhóm bài tập ',
    folderName: 'Tên nhóm bài tập',
    startDateTxt: 'Ngày bắt đầu',
    endDateTxt: 'Ngày kết thúc',
    lastWeek: '7 ngày qua',
    LastMonth: '30 ngày qua',
    LastYear: '12 tháng qua',
    todayTxt: 'Hôm nay',
    sort: 'Sắp xếp',
    newest: 'Mới nhất',
    oldest: 'Cũ nhất',
    createNewFolder: 'Tạo thư mục mới',
    updateFolder: 'Đổi tên thư mục',
    changeName: 'Đổi tên',
    prompt: 'Xác nhận xóa?',
    deleteFolderPrompt:
      'Lưu toàn bộ bài tập và tài liệu trong thư mục con đến thư mục ”Bài tập chưa xác định“',
    createTask: 'Tạo bài tập',
    createPaper: 'Tạo đề thi',
    taskName: 'Tên bài tập',
    taskDescribe: 'Mô tả bài tập',
    verTxt: {
      taskName: 'Nhập tên bài tập',
    },
    distributeTask: 'Giao bài',
    saveDraft: 'Lưu nháp',
    saveAndPublishLater: 'Lưu & giao sau',
    taskToClass: 'CHỌN LỚP GIAO BÀI TẬP NÀY',
    canQuestionTime: 'THỜI GIAN CHO PHÉP LÀM BÀI',
    viewAnswers: 'XEM ĐÁP ÁN ',
    answersOrder: 'ĐẢO THỨ TỰ ĐÁP ÁN',
    takeInteger: 'LÀM TRÒN ĐIỂM',
    atAnyTimeQuestions: 'Học sinh có thể vào làm bài mọi lúc',
    timingMakeQuestions: 'Học sinh chỉ có thể làm trong khoảng thời gian',
    onCompletionLook: 'Khi học sinh làm bài xong',
    afterTimeoutLook: 'Khi quá thời gian cho phép làm bài ',
    notAllowLook: 'Không cho học sinh xem đáp án',
    yesText: 'Có',
    noText: 'Không',
    reachTxt: 'đến',
    submittedTaskStudent: 'Số hs đã nộp',
    unlimitedTxt: 'Không có',
    actionTxt: 'Hành động',
    moveFolder: 'Gán nhóm bài tập',
    shareTxt: 'Chia sẻ',
    personalTxt: 'Cá nhân',
    updateTask: 'Sửa bài tập',
    updateTaskCont1: 'Bài tập đã được giao đến học sinh',
    updateTaskCont2: 'Bạn chỉ có thể sửa thông tin bài tập。',
    updateTaskCont3:
      'Nếu muốn sửa hoặc cài đặt lại câu hỏi, hãy tiến hành sửa đổi trên bản sao！',
    createBackupsVersion: 'Tạo bản sao',
    deleteTask: 'Xóa bài tập ?',
    deleteDraftTask: 'Bạn có chắc chắn muốn xóa bài tập này không ???',
    deletePublishedTask:
      'Bạn có chắc chắn muốn xóa bài tập này không ??? (Các lớp được giao chung bài tập này cũng bị xóa! Điểm và bài tập này trong danh sách bài tập của học sinh sẽ bị xóa hết)',
    selectFolderToMigrate: 'Chọn nhóm bài tập ',
    clickCopyTxt: 'Nhấn copy để chia sẻ liên kết',
    shareDescribe: 'Mô tả nội dung chia sẻ',
    printTxt: 'In',
    completedTask: 'Số bài tập đã làm ',
    copy: 'Sao chép',
    copySuccessTxt: 'Sao chép thành công',
    publishedTask: 'Bài tập đã giao ',
    taskList: 'Danh sách bài tập',
    alreadyDo: 'Đã làm',
    notDo: 'Chưa làm',
    taskContent: 'Bài tập',
    releaseTime: 'Giao ngày',
    personalInfoDescribeTxt:
      'Thông báo Admin trường khi muốn chỉnh sửa thông tin',
    stemTxt: 'Đề bài',
    makeQuestionList: 'Danh sách làm bài',
    taskInfo: 'Thông tin bài tập',
    idTxt: 'ID',
    titleTxt: 'Tiêu đề',
    totalNumber: 'Số câu',
    timeWorking: 'Thời lượng làm bài',
    subPaperTime: 'Hạn nộp bài',
    subPaperNum: 'Số học sinh đã nộp',
    noTxt: 'Không có',
    againDistributeTask: 'Giao lại bài',
    allTxt: 'Tất cả',
    scoredTxt: 'Đã chấm điểm',
    notScoredTxt: 'Chưa chấm điểm',
    previewMarkHistory: 'Xem lại',
    notSubPaper: 'Chưa nộp bài',
    scoreTxt: 'Chấm điểm',
    studentAnswer: 'Đáp án của học sinh',
    paperRightAnswer: 'Đáp án  đúng của đề',
    previewTxt_1: 'Đáp án câu',
    previewTxt_2: 'Câu trả lời cho câu hỏi',
    mostTxt: 'Nhiều nhất',
    modifyScore: 'Chỉnh sửa điểm số',
    resultTxt: 'Kết quả',
    historyTxt: 'Lịch sử',
    scoreEachTopic: 'Chấm điểm từng câu',
    writtenExamQst: 'Tự luận',
    historyInfo: 'Thông tin lịch sử',
    startTime: 'Thời gian bắt đầu',
    submitTime: 'Thời gian nộp bài ',
    submitStartTime: 'Bắt đầu làm bài',
    completeScored: 'Hoàn thành chấm điểm',
    completeScoredTxt:
      'Sau khi chấm điểm, kết quả của bài tập sẽ được hiển thị ở phía học sinh. Bạn có chắc chắn muốn hoàn thành không? ',
    continueScored: 'Tiếp tục chấm bài',
    modifyFraction: 'Chỉnh sửa điểm ',
    teacherComment: 'Lời phê của giáo viên',
    modifyComment: 'Chỉnh sửa lời phê',
    continueNoStudentTxt:
      'Bạn đã hoàn thành chấm điểm những học sinh đã nộp bài. Bạn có muốn quay lại màn hình danh sách câu hỏi của học sinh không?',
    noAnswer: 'Chưa trả lời',
    hasATimeoutTxt: 'Đã quá hạn',
    selectNextStudentScored: 'Chọn học sinh tiếp theo để chấm bài',
    seek: 'Tìm kiếm',
    classStudent: 'Lớp học ',
    practiceClass: 'Bài tập lớp',
    distributeNow: 'Giao lại ngay',
    deleteQuestion: 'Xoá câu hỏi?',
    deleteQuestionTxt: 'Bạn có chắc chắn muốn xóa câu hỏi?',
    distribution: 'Gán',
    distributionTask: 'Gán bài tập',
    searchTaskName: 'Tìm kiếm tên bài tập',
    taskNameRetrieval: 'Tìm tên bài tập/ID',
    noneAtPresent: 'Chưa có',
    markScoreFront: 'Câu thứ',
    markScoreAfter: 'chưa được chấm điểm',
    seekStudentAndClass: 'Tìm học sinh lớp',
    markExamPaper: 'Bài đã chấm',
    rename: 'Đổi tên',
    renameGuide: 'Nhập tên bạn muốn thay đổi',
    questionNumTxt: '{0} câu',
    endTime: 'Thời gian kết thúc',
    expiresLater: 'Hết hạn sau',
    day: 'ngày',
    toTime: 'tới',
    minutes: 'phút',
    automaticGrading: 'Tự động chấm bài và trả điểm',
    noteCheck:
      '(<span style="color: red">*</span>Không áp dụng với đề có câu hỏi tự luận hoặc điền vào chỗ trống)',
    createClone: 'Tạo bản sao',
    newFolder: 'Thư mục mới',
    delete: 'Xóa',
    lastTimeUpdate: 'Lần cuối chỉnh sửa',
    successRename: 'Đổi tên thư mục thành công',
    successAddFolder: 'Thêm thư mục thành công',
    successDeleteFolder: 'Xóa thư mục thành công',
    deleteFolder: 'Xóa thư mục',
    confirmDeleteFolder: 'Bạn có chắc chắn muốn xóa thư mục này không?',
    warringNotChangeName: 'Tên đề bị trùng vui lòng nhập tên khác',
    successMoveExam: 'Đã di chuyển đề thi tới "{0}"',
    successReameExam: 'Đổi tên đề thi thành công',
    successDeleteExam: 'Xóa đề thi thành công',
    searchFolder: 'Tìm kiếm thư mục',
    createOneFolder: 'Tạo 1 thư mục mới',
    emptyFolder: 'Chưa có thư mục nào.',
    createFolderTxt: 'Tạo thư mục',
    waringEmptyFolder: 'Bạn cần chọn thư mục để thêm đề thi',
    viewDetailClass: 'Xem chi tiết lớp học',
    updateClass: 'Cập nhật lớp học',
    removeClass: 'Xóa lớp học',
    removeTeacher: 'Xóa giáo viên',
    updateTeacher: 'Chỉnh sửa thông tin giáo viên',
    successCloneExam: 'Tạo bản sao đề thi thành công',
    searchPaperTxt: 'Tìm tên đề thi/ID',
    warringEmptyFolder: 'Bạn vui lòng tạo thư mục mới',
  },

  publishJob: {
    zuoye: 'Bài tập về nhà',
    kaojuan: 'Bài kiểm tra',
  },

  notify: {
    order: 'STT',
    notifyTltle: 'Tiêu đề thông báo',
    createPeople: 'Người tạo',
    notifyTime: 'Thời gian thông báo',
    deleteNotify: 'Xoá thông báo ',
    deleteNotifyTxt: 'Xác nhận xoá thông báo này?',
    createNotify: 'Tạo thông báo',
    notifyContent: 'Nội dung thông báo',
    getNotifyObj: 'Đối tượng nhận thông báo ',
    teacherCode: 'Mã giáo viên ',
    inputTeacherCodeOrName: 'Mã hoặc họ tên giáo viên ',
    selected: ' Đã có',
    selectedTeacher: 'giáo viên được chọn',
    selectedStudent: 'học sinh được chọn',
    surveyNotify: 'Thông báo chung',
    systemNotify: 'Thông báo hệ thống',
    ureadTxt: 'Chưa đọc',
    newTxt: 'Mới',
    beforeTxt: 'Trước đó',
    tabReadTxt: 'Đánh dấu là đã đọc',
    tabAllRead: 'Đánh dấu toàn bộ là đã đọc',
    removeNotify: 'Xóa thông báo',
    completeAfterNotify: 'Ngay sau khi hoàn thành khởi tạo thông báo',
    customTime: 'Tùy chỉnh thời gian',
    inputName: 'Nhập tên',
    notifiedTxt: 'Đã thông báo',
    notNotifiedTxt: 'Chưa thông báo',
    monthFrontTxt: ' tháng trước',
    weekFrontTxt: ' tuần trước',
    dayFrontTxt: ' ngày trước',
    hourFrontTxt: ' giờ trước',
    minFrontTxt: ' phút trước',
    justTxt: ' vừa mới',

    startTxt: 'Bắt đầu',
    endTxt: 'Kết thúc',
    addPlaceholderTxt: 'Hẹn giờ gửi thông báo',
    mustCreateNextSchoolYear: 'Bạn phải tạo năm học mới trước',
  },

  menu: {
    taskManage: 'Quản lý bài tập',
    scored: 'Chấm bài',
    distributeTask: 'Giao bài',
    createTask: 'Tạo bài tập',
    classInfo: 'Thông tin lớp học',
    info: 'Thông tin',
    studentList: 'Danh sách học sinh',
    teacherList: 'Danh sách giáo viên',
  },

  guide: {
    done: 'Xong',
    ignore: 'Bỏ qua',
    learned: 'Tôi đã hiểu nội dung này.',
    stepTxt: '<strong>Bước {0}/</strong>{1}',
    taskManage: 'Quản lý bài tập',
    guideTaskManage:
      'Bao gồm các chức năng: Chấm bài, Giao bài và Tạo bài tập cho lớp',
    questionBank: 'Ngân hàng câu hỏi',
    guideQuestionBank:
      'Giáo viên có thể sử dụng Ngân hàng câu hỏi được cung cấp sẵn để tạo bài tập, hoặc thêm về Kho cá nhân để quản lý',
    testPaperBank: 'Ngân hàng đề thi',
    guideTestPaperBank:
      'Giáo viên quản lý Kho đề cá nhân và sử dụng Ngân hàng đề thi được tuyển chọn',
    classInfo: 'Thông tin lớp học',
    guideClassInfo:
      'Bao gồm Thông tin lớp. Danh sách học sinh, Danh sách giáo viên của những lớp mà giáo viên đó phụ trách',
    filter: 'Filter lọc',
    guideFilter: 'Lọc bài tập theo các giá trị mong muốn',
    menuClass: 'Menu tab Lớp - Môn',
    guideMenuClass: 'Các Lớp - môn mà giáo viên phụ trách. Click để chuyển tab',
    scored: 'Chấm bài',
    guideScored:
      'Đối với những Bài tập có trạng thái Chưa hoàn thành. Click để chấm bài!',
    detailsTxt: 'Chi tiết',
    guideDetail:
      'Đối với những Bài tập có trạng thái Đã hoàn thành. Click để xem chi tiết Kết quả chấm bài!',
    distributeTask: 'Giao bài',
    guideDistributeTask: 'Click vào đây để Giao bài!',
    unDelivered: 'Chưa giao',
    guideUnDelivered: 'Danh sách bài tập đã tạo nhưng Chưa giao cho lớp',
    createTask: 'Tạo bài tập',
    guideCreateTask: 'Click tạo bài tập!',
    filterTask: 'Lọc bài tập',
    guideFilterTask: 'Có thể lọc bài tập theo Filter hoặc theo Nhóm bài tập.',
    delivered: 'Đã giao',
    guideDelivered: 'Danh sách các bài tập đã giao',
    guideDetailTask:
      'Click để xem chi tiết bài tập đã giao bao gồm Kết quả làm bài tập và Đề bài',
    guideFilterFschool: 'Lọc câu hỏi theo Khối, Môn mong muốn',
    view: 'Xem',
    guideViewNodeTree:
      'Click để duyệt danh sách câu hỏi theo Cây kiến thức. Giáo viên có thể click vào tên node kiến thức hoặc nút Xem đều có thể duyệt được.',
    guideFilterQuestionFschool: 'Tìm kiếm câu hỏi theo Dạng câu hỏi và Độ khó',
    guideCreateTaskFschool: 'Có thể tạo bài tập nhanh từ đây!',
    addAll: 'Thêm tất cả',
    guideAddAll: 'Thêm tất cả câu hỏi ở trang hiện tại vào Kho cá nhân.',
    dashboard: 'Tổng quan',
    guideDashboard:
      'Dashboard giúp Admin có cái nhìn tổng quan về số liệu trong hệ thống.',
    orgManage: 'Quản lý tổ chức',
    guideOrgManage:
      'Admin có thể xem thông tin của trường học cũng như là các lớp học trong trường, bên cạnh đó là việc các thao tác liên quan đến việc tổ chức trường.',
    userManage: 'Quản lý người dùng (Quản trị viên, Giáo viên, Học sinh)',
    guideUserManage:
      'Admin có thể xem được danh sách của từng vai trò đồng thời có thể thêm, sửa, xóa từng vai trò tương ứng.',
    roleManage: 'Quản lý người dùng (Quản lý vai trò)',
    guideRoleManage:
      'Admin có thể thêm, sửa tên và phân quyền cho từng vai trò tương ứng.',
    adminGuidePaperBank:
      'Admin quản lý được ngân hàng đề thi của trường. Đồng thời có thể tham khảo thêm các đề thi có sẵn trong kho Fschool.',
    adminGuideQuestionBank:
      'Admin quản lý được ngân hàng câu hỏi của trường. Đồng thời có thể tham khảo thêm các đề thi có sẵn trong kho Fschool',
    statistical: 'Thống kê',
    guideStatistical: 'Thống kê các chỉ số của trường ở thời điểm hiện tại.',
    practice: 'Ôn luyện',
    guidePractice:
      'Admin có thể quản lý danh sách đề ôn luyện của trường ở thời điểm hiện tại.',
  },

  statistical: {
    statisticalTxt: 'Thống kê',
    wholeSystemStatistical: 'Thống kê toàn hệ thống',
    bySchoolStatistical: 'Thống kê theo trường',
    overviewStatistics: 'Thống kê chung',
    endTime: 'Tính tới ngày',
    primarySchool: 'Tiểu học',
    middleSchool: 'Trung  học cơ sở',
    highSchool: 'THPT',
    total: 'Tổng ',
    accountStatisticsByTime: 'Thống kê tài khoản theo thời gian',
    day: 'Ngày',
    month: 'Tháng',
    questionPpaperStatistical: 'Thống kê về câu hỏi và đề thi',
    keyWordStatistical: 'Thống kê về từ khóa',
    backList: 'Quay lại danh sách',
    accountStatistical: 'Thống kê tài khoản',
    statisticsOfStudentsByStatus:
      'Thống kê tài khoản học sinh theo trạng thái ',
    accountTotal: 'Tổng số tài khoản',
    activeUser: 'Tài khoản hoạt động',
    inactiveUser: 'Tài khoản không hoạt động',
    keyWord: 'Từ khóa',
    frequency: 'Số lần',
    wiewList: 'Xem danh sách',
    getSeveralTimes: 'Số lượt lấy về',
  },
  homeTeacher: {
    newPaper: 'Đề thi mới',
    newPaperTxt: 'Đề mới',
    theLast30Days: '30 ngày qua ({0} {1})',
    theLast7Days: '7 ngày qua ({0} {1})',
    theLast24Hours: '24 giờ qua ({0} {1})',
    newQuestion: 'Câu hỏi mới',
    class: 'Lớp',
    type: 'Loại',
    difficulty: 'Độ khó',
    lastMonthPaper: '30 ngày qua ( {0} đề mới)',
    lastWeekPaper: '7 ngày qua ( {0} đề mới)',
    lastDayPaper: 'Hôm nay ( {0} đề mới)',
    lastMonthQuestion: '30 ngày qua ( {0} câu mới)',
    lastWeekQuestion: '7 ngày qua ( {0} câu mới)',
    lastDayQuestion: 'Hôm nay ( {0} câu mới)',
    goBack: 'Quay lại',
    searchTxt: 'Tìm kiếm...',
    categoryTxt: 'Danh mục đề',
    latest: 'Mới nhất',
    answer: 'Đáp án',
    collapse: 'Thu gọn',
    expand: 'Mở rộng',
    correctAnswer: 'Đáp án đúng',
    analysis: 'Giải thích',
    addQuestionBtn: 'Thêm vào kho cá nhân',
    addedBtn: 'Đã thêm',
  },
  homeSchool: {
    mostRecentMonth: '{0} tháng gần nhất',
    mostRecentDay: '{0} ngày gần nhất',
    traffic: 'Lưu lượng truy cập',
    day: 'Ngày',
    month: 'Tháng',
    asscesNum: 'Số lần truy cập',
    students: 'Học sinh',
    teachers: 'Giáo viên',
    paperAndExam: 'Đề thi - Bài tập',
    question: 'Câu hỏi',
    statisticalAccount: 'Thống kê tài khoản',
    exportExcel: 'Xuất file excel',
    student: 'Học sinh',
    teacher: 'Giáo viên',
    total: 'Tổng: {0}',
    class: 'Lớp',
    questionBank: 'Ngân hàng câu hỏi',
    examBank: 'Ngân hàng đề thi',
    school: 'Trường',
    examNum: '{0} đề',
    questionNum: '{0} câu',
    theExamThatGotTheMost: 'Đề thi lấy về nhiều nhất',
    TheMostPracticeExamQuestions: 'Đề thi được chọn luyện tập nhiều nhất',
    name: 'Tên',
    numTurms: 'Số lượt',
    operation: 'Thao tác',
    viewDetail: 'Xem chi tiết',
    fschoolBank: 'Ngân hàng Fschool',
    schoolBank: 'Ngân hàng trường',
    teacherBank: 'Ngân hàng giáo viên',
    examTxt: 'Đề thi',
    statisticalChartExamBySubject:
      'Biểu đồ số lượng đề thi theo Môn (Đơn vị: Đề thi)',
    statisticalChartExamByGrade:
      'Biểu đồ số lượng đề thi theo Khối (Đơn vị: Đề thi)',
    statisticalChartQuestionByDifficult:
      'Biểu đồ số lượng câu hỏi theo Độ khó (Đơn vị: Câu hỏi) ',
    statisticalChartQuestionByType:
      'Biểu đồ số lượng câu hỏi theo Dạng câu hỏi (Đơn vị: Câu hỏi) ',
    questionTxt: 'Câu',
    examAndPaper: 'Đề thi và bài tập',
  },
  statisticalTeacher: {
    allTxt: 'Tất cả',
    class: 'Lớp',
    paper: 'Bài tập',
    exam: 'Đề thi',
    question: 'Câu hỏi',
    schoolYear: 'Năm học',
    scoreStatisticalTitle: 'Điểm trung bình {1} theo môn của lớp năm học {0}',
    wrongStatisticalTitle:
      'Danh sách các chương, bài mà học sinh có câu làm sai nhiều nhất',
    paperStatisticalTitle: 'Thống kê bài tập theo lớp năm học {0}',
    questionStatisticalTitle: 'Biểu đồ thể hiện số lượng {1} của giáo viên',
    mostExamtatisticalTitle: 'Đề thi được sử dụng nhiều nhất',
    oneMonthAgo: '1 tháng trước',
    fourMonthsAgo: '4 tháng trước',
    timeTxt: 'Thời gian',
    teacherTxt: 'Giáo viên',
    studentTxt: 'Học sinh',
    aptitudeExam: 'Đánh giá năng lực',
    numDownloads: 'Lượt tải',
    statisticalTxt: 'Thống kê',
    classTxt: 'Lớp học',
    teachingSubject: 'Môn giảng dạy',
    classSize: 'Sĩ số lớp',
    numberOfTestsDelivered: 'Số bài kiểm tra đã giao',
    numberOfPaperDelivered: 'Số bài tập đã giao',
    listClassesByYear: 'Danh sách các lớp giáo viên dạy {0}',
    testPaperName: 'Tên bài tập/kiểm tra',
    deliveryTime: 'Thời gian giao bài',
    expiredTime: 'Thời gian hết hạn',
    examType: 'Dạng đề',
    seeTopic: 'Xem đề bài',
    seeTaskList: 'Xem danh sách bài làm',
    pointLadder: 'Thang điểm',
    selectTaskType: 'Chọn dạng bài',
    selectDeliveryTime: 'Chọn thời gian giao',
    selectExpiredTime: 'Chọn thời gian hết hạn',
    selectFractionSystem: 'Chọn thang điểm',
    inputSearchNameExam: 'Tìm theo tên bài tập/kiểm tra',
    totalNumberOfExam: 'Tổng số bài đã giao: {0}',
    inputSearchStudentName: 'Tìm theo tên học sinh',
    selectClass: 'Chọn lớp',
    selectTaskState: 'Chọn trạng thái làm bài',
    selectRangeScore: 'Chọn khoảng điểm',
    studentName: 'Tên học sinh',
    examState: 'Trạng thái làm bài',
    score: 'Điểm',
    totalStudentsDelivery: 'Tổng số học sinh được giao bài: {0}',
    totalClassDelivery: 'Tổng số lớp được giao bài: {0}',
    wrongTurn: 'Lượt làm sai',
    practiceTurn: 'Lượt luyện tập',
    correctRatio: 'Tỷ lệ đúng',
    ratings: 'Bảng xếp hạng',
    charts: 'Biểu đồ',
    titleRatings: 'Bảng xếp hạng học sinh lớp {0} môn {1}',
    ratingsTxt: 'Xếp hạng',
    agvScore: 'Điểm trung bình',
    titleAvgScore: 'Biểu đồ điểm trung bình của học sinh lớp {0} môn {1}',
    taskTxt: 'Kiểm tra/ bài tập',
    questionTxt: 'câu hỏi',
    examTxt: 'đề thi',
  },
}
