import { createRouter, createWebHistory } from 'vue-router'
import adminRouter from './adminRouter'
import { nextTick } from 'vue'

const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/home/Login.vue'),
    meta: {
      title: 'Đăng nhập',
    },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
  {
    path: '/view_file',
    name: 'raw data',
    component: () => import('../views/viewFile/viewRawData.vue'),
    meta: {
      title: 'Kết quả số hóa',
    },
  },
  adminRouter,
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})
router.beforeEach((to, from, next) => {
  const index = to.path.search(/\/admin/)
  if (index === -1) {
    if (to.path === '/login') {
      window.localStorage.getItem('adminInfo') ? next('/admin/main') : next()
    } else {
      next()
    }
  } else {
    const adminInfo =
      localStorage.getItem('adminInfo') &&
      JSON.parse(localStorage.getItem('adminInfo'))
    if (adminInfo) {
      if (to.meta.roles && to.meta.roles.includes(adminInfo.userType)) {
        next()
      } else {
        next('/')
      }
    } else {
      next('/login')
    }
  }

  // * SET PAGE TITLE
  nextTick(() => {
    document.title = to.meta.title || 'Fschool'
  })
})
export default router
