export default {
  state: {
    defaultLang: sessionStorage.getItem('useLang')
      ? sessionStorage.getItem('useLang')
      : 'vi',
  },
  mutations: {
    setDefaultLang(state, lang) {
      console.log('setDefaultLang ==============>', lang)
      state.defaultLang = lang
    },
  },
}
