import { createI18n } from 'vue-i18n'

import zh_cn from './langPacket/zh-cn'
import en from './langPacket/en'
import vi from './langPacket/vi'

const langPacket = createI18n({
  locale: sessionStorage.getItem('useLang')
    ? sessionStorage.getItem('useLang')
    : 'vi',
  messages: {
    zh_cn,
    en,
    vi,
  },
  legacy: false,
})

const langList = [
  { label: '中文简体', lang: 'zh_cn' },
  { label: 'English', lang: 'en' },
  { label: 'ViệtName', lang: 'vi' },
]

let langConfig = { langList, langPacket }

export default langConfig
